(function($){
   var scrollToForm = function() {
      $('html, body').animate({ scrollTop: $('.block-form').offset().top - 170 }, 100);
   };

   window.setupForm = function( formID, sessionStorage ) {
      switch ( formID ) {
         case 'free_mortgage_consultation_form':

         if ( $('#free_mortgage_consultation_form').length ) {
            var step_number = -1;
            var queryString = getQueryParameters();

            if ( queryString.step !== undefined ) {
               $('.block-step').hide();
               step_number = queryString.step;
            } else {
               step_number = 1;
            }
            $( '.step' + step_number ).show();

            if ( sessionStorage['referring_branch'] != undefined && sessionStorage['referring_branch'] !== "" ) {
               if ( sessionStorage['referring_branch'] !== "Independence" ) {
                  $("input[name='cid']").val(branchMetaInfo[sessionStorage['referring_branch']][1]);
               }
            }

            $( '#free_mortgage_consultation_form' ).nlcValidate({
               debug: true,
               multistep: true,
               submitHandler: function( form ) {

                  var $currentStep = $( form ).find('div.block-step').filter( function( index, element ){
                     return $( element ).css('display') === 'block';
                  });
                  var stepNumber = parseInt( $currentStep.attr('class').split(' ')[0].slice(-1) );

                  if ( stepNumber === 1 ) {
                     // Get the values from the form to pre-populate
                     var mortgagegoals = $('select[name="mortgagegoals"]').val();
                     var propertyType = $('select[name="propertyType"]').val();
                     var pzipcode = $('input[name="propertyzip"]').val();
                     var estpropvalue = $('select[name="estpropvalue"]').val();
                     var currentmortbal = $('select[name="currentmortbal"]').val();

                     var url = getProtocolandHost() + "free-mortgage-consultation/?mortgagegoals=" + encodeURIComponent(mortgagegoals) + "&pzipcode=" + encodeURIComponent(pzipcode) +
                     "&estpropvalue=" + encodeURIComponent(estpropvalue) + "&currentmortbal=" + encodeURIComponent(currentmortbal) + "&propertyType=" + encodeURIComponent(propertyType) + "&step=2";

                     window.location.href = url;
                  } else {
                     // Obtain the next step's class
                     currentStepClass = "step" + (stepNumber + 1);

                     // Hide the current step and then either show the next step or thank you page
                     $currentStep.hide();

                     if ( $('.' + currentStepClass).length > 0 ) {
                        scrollToForm();
                        $('.' + currentStepClass).show();
                        if ( $('.' + currentStepClass).hasClass('last-step') ) {
                           $( '#free_mortgage_consultation_form input[type="submit"]' ).val('Submit');
                        }
                     } else if ( $currentStep.next().hasClass('thank-you') || $currentStep.next().next().hasClass('thank-you') ) { // We have reached the end of the form
                        // Get the URL parameters and put them into the inputs
                        var queryParameters = getQueryParameters();
                        $('select[name="mortgagegoals"]').val(queryParameters['mortgagegoals']);
                        $('select[name="propertyType"]').val(queryParameters['propertyType']);
                        $('input[name="propertyzip"]').val(queryParameters['pzipcode']);
                        $('select[name="estpropvalue"]').val(queryParameters['estpropvalue']);
                        $('select[name="currentmortbal"]').val(queryParameters['currentmortbal']);

                        scrollToForm();
                        $("#free_mortgage_consultation_form input[type='submit']").hide();
                        $( '#free_mortgage_consultation_form .step-loader' ).css('display', 'inline-block');

                        // Submit the data
                        $.ajax({
                           type: "POST",
                           crossDomain: true,
                           dataType: "jsonp",
                           url: $( form ).attr('action'),
                           data: $( form ).serialize()
                        }).always( function(data, textStatus, jqXHR){
                           $( '#free_mortgage_consultation_form .step-loader' ).hide();
                           $( '#free_mortgage_consultation_form input[type="submit"]' ).hide();

                           $( '#free_mortgage_consultation_form div.thank-you' ).show();
                        }).fail( function(jqXHR, textStatus, errorThrown){
                        });

                     }
                  }
               }
            });
         }
            break;
         case 'pre_qualification_form':
            if ( $('#pre_qualification_form').length && document.getElementById( 'pre_qualification_form' ) !== undefined ) {
               var step_number = -1;
               var queryString = getQueryParameters();
               //alert(queryString.step);
               if ( queryString.step !== undefined ) {
                  step_number = queryString.step;
               } else {
                  step_number = 1;
               }

               //alert(step_number);
               // Show the appropriate step

               if ( queryString.loantype !== undefined ) {
                  //alert('firing');
                  scrollToForm();
                  $('input[name="loantype"]').val( queryString.loantype );
                  var new_loan_type = queryString.loantype.toLowerCase().split(/\s+/).join('-');
                  $('.block-step').hide();
                  $( "." + new_loan_type + "-step" + step_number ).show();
               } else {
                  $( '.step' + step_number ).show();
                  if ( step_number === 1 ) {
                     $('#pre_qualification_form .submit-button').hide();
                  }
               }

               if(queryString.loantype !== undefined && step_number == 1){
                  scrollToForm();
                  $('.submit-button').hide();
                  $('.step1.block-step').show();
               }

               if ( queryString.leadSource !== undefined ) {
                  $('input[name="leadSource"]').val( queryString.leadSource );
               }

               // We are going to be directing the user to a second page, so pass the zip code in as a URL parameter
               if ( queryString.pzipcode !== undefined ) {
                  $('input[name="propertyzip"]').val( queryString.pzipcode );
               }
               if ( sessionStorage.referring_branch !== null && sessionStorage.referring_branch !== "" ) {
                  $('input[name="referringBranch"]').val( sessionStorage.referring_branch );
               }

               // Used to not send the leads to Velocify if the user came from a branch form.
               // Only organic leads should go through to Velocify correctly.

               if ( sessionStorage.referring_branch !== undefined && sessionStorage.referring_branch !== "" ) {
                  if ( sessionStorage.referring_branch !== "Independence" ) {
                     $("#pre_qualification_form input[name='cid']").val(branchMetaInfo[sessionStorage['referring_branch']][1]);
                  }
               }

               // Pre-Qualification Validation
               $( '#pre_qualification_form' ).nlcValidate({
                  // debug: true,
                  multistep: true,
                  submitHandler: function( form ) {
                     var $currentStep = $( form ).find('div.block-step').filter( function( index, element ){
                        return $( element ).css('display') === 'block';
                     });

                     var stepNumber = parseInt( $currentStep.attr('class').split(' ')[0].slice(-1) );

                     if ( stepNumber === 1 ) {
                        var pzipcode = $('input[name="propertyzip"]').val();
                        var loantype = $('.loan-type-button.active').text();

                        var url = getProtocolandHost() + "pre-qualification-form/?pzipcode=" + encodeURIComponent(pzipcode) + "&loantype=" + encodeURIComponent(loantype) + "&step=2";

                        window.location.href = url;
                     } else {
                        // Obtain the next step's class
                        currentStepClass = "step" + (stepNumber + 1);

                        // Hide the current step and then either show the next step or thank you page
                        $currentStep.hide();

                        if ( $('.' + currentStepClass).length > 0 ) {
                           scrollToForm();
                           $('.' + currentStepClass).show();
                           if ( $('.' + currentStepClass).hasClass('last-step') ) {
                              $( '#pre_qualification_form input[type="submit"]' ).val('Submit');
                           }
                        } else if ( $currentStep.next().hasClass('thank-you') || $currentStep.next().next().hasClass('thank-you') ) { // We have reached the end of the form
                           scrollToForm();
                           $("#pre_qualification_form input[type='submit']").hide();
                           $( '#pre_qualification_form .step-loader' ).css('display', 'inline-block');

                           // Submit the data...duh
                           $.ajax({
                              type: "POST",
                              crossDomain: true,
                              dataType: "jsonp",
                              url: $( form ).attr('action'),
                              data: $( form ).serialize()
                           }).always( function(data, textStatus, jqXHR){
                              $( '.step-loader' ).hide();
                              $( '#pre_qualification_form input[type="submit"]' ).hide();

                              $( '#pre_qualification_form div.thank-you' ).fadeIn(200);
                           }).fail( function(jqXHR, textStatus, errorThrown){
                           });
                        }
                     }
                  }
               });
            }
            break;
         case 'pdf_form':
            /* Download functionality */
            if ( document.querySelector('.pdf-form') !== undefined && document.querySelector('.pdf-form') !== null ) {

               $( '.pdf-form' ).nlcValidate({
                  submitHandler: function( form  ) {
                     var email_val = document.querySelector('input[name="pdfemail"]').value;
                     $curForm = $( form );
                     // Send ajax POST to my email script which sends an email with the user's information

                     $(".pdf-form input[type='submit']").hide();
                     $( '.pdf-form .step-loader' ).css('display', 'inline-block');
                     $.ajax({
                        type: "POST",
                        crossDomain: true,
                        dataType: "jsonp",
                        url: $( form ).attr('action'),
                        data: $( form ).serialize()
                     }).always( function(data, textStatus, jqXHR){
                        $( '.pdf-form .step-loader' ).hide();
                        $('.pdf-form input[type="submit"]').hide();
                        // /* Second method - using a hidden iframe to download */
                        var url = $curForm.find('input[name="pdfURL"]').val();
                        /* Third method - creating a temporary hyperlink and trigger it's click */
                        var hidden_download_link = document.createElement('a');
                        hidden_download_link.id = "hidden_download_link";
                        hidden_download_link.setAttribute('href', url);
                        hidden_download_link.target = "_blank";
                        hidden_download_link.style.display = 'none';
                        document.getElementById( $curForm.attr('id') ).appendChild(hidden_download_link);
                        if (hidden_download_link.download !== undefined) {
                           hidden_download_link.download = url.substring(url.lastIndexOf('/') + 1, url.length);
                        }

                        // Dispatching click event
                        if ( document.createEvent ) {
                           var e = document.createEvent('MouseEvents');
                           e.initEvent('click', true, true);
                           hidden_download_link.dispatchEvent(e);
                        }
                        // Create little thank you page.
                        $curForm.parent('.content-container')
                        .find('span.icon-HouseKey-01').removeClass('icon-HouseKey-01').addClass('icon-House-01').end()
                        .find('.dl-header').text('').end()
                        .find('.dl-subcontent').text( $curForm.find('input[name="dldMessage"]').val() ).end();

                        $curForm.hide();
                     });
                  }
               });
            }
            break;
         case 'download_infographic_form':
            /* Download functionality */
            if ( document.querySelector('#download_infographic_form') !== undefined && document.querySelector('#download_infographic_form') !== null ) {

               $( '#download_infographic_form' ).nlcValidate({
                  submitHandler: function( form  ) {
                     var email_val = document.querySelector('input[name="pdfemail"]').value;
                     $curForm = $( form );
                     // Send ajax POST to my email script which sends an email with the user's information

                     $(".download_infographic_form input[type='submit']").hide();
                     $.ajax({
                        type: "POST",
                        crossDomain: true,
                        dataType: "jsonp",
                        url: $( form ).attr('action'),
                        data: $( form ).serialize()
                     }).always( function(data, textStatus, jqXHR){
                        alert('Form Fired Successfully');
                     });
                  }
               });
            }
            break;
         case 'contact_us_form':
            // Contact Us form
            if ( $('#contact_form').length > 0 ) {
               if ( sessionStorage['referring_branch'] != undefined && sessionStorage['referring_branch'] !== "" ) {
                  if ( sessionStorage['referring_branch'] !== "Independence" ) {
                     $("#contact_form input[name='cid']").val(branchMetaInfo[sessionStorage['referring_branch']][1]);
                  }
               }

               $( '#contact_form' ).nlcValidate({
                  // debug: true,
                  submitHandler: function( form ) {
                     // Send the user-submitted information to an email
                     // First get the values from the fields

                     var fname_val = document.querySelector('input[name="firstName"]').value;
                     var lname_val = document.querySelector('input[name="lastName"]').value;
                     var pnumber = document.getElementById('phone_number').value;
                     var email_val = document.querySelector('input[name="emailAddress"]').value;
                     var comments_val = document.querySelector('textarea[name="comments"]').value.replace(/\s+/, '');
                     comments_val = comments_val === "" ? "" : comments_val;

                     $('.step1').hide();

                     $("#contact_form input[type='submit']").hide();
                     $( '#contact_form .step-loader' ).css('display', 'inline-block');
                     // Send ajax POST to my email script which sends an email with the user's information
                     $.ajax({
                        type: "POST",
                        crossDomain: true,
                        dataType: "jsonp",
                        url: $( form ).attr('action'),
                        data: $( form ).serialize()
                     }).always( function(data, textStatus, jqXHR){
                        // Hide Step 1 and show the thank you section
                        $( '#contact_form .step-loader' ).hide();
                        $('#contact_form input[type="submit"]').hide();
                        $('#contact_form .thank-you').show();
                     }).fail( function(jqXHR, textStatus, errorThrown){
                        $( '#contact_form .step-loader' ).hide();
                        $('#contact_form input[type="submit"]').hide();
                        $('#contact_form .thank-you').show();
                     });
                  }
               });
            }
            break;
         case 'direct_mail_form':
            // Direct Mailer forms
            if ( $('.direct-mail-form').length > 0 ) {
               $( '.direct-mail-form' ).nlcValidate({
                  multistep: true,
                  submitHandler: function( form ) {
                     var $currentStep = $( form ).find('div.block-step').filter( function( index, element ){
                        return $( element ).css('display') === 'block';
                     });
                     var stepNumber = parseInt( $currentStep.attr('class').split(' ')[0].slice(-1) );

                     // Obtain the next step's class
                     currentStepClass = "step" + (stepNumber + 1);

                     // Hide the current step and then either show the next step or thank you page
                     $currentStep.hide();

                     if ( $('.' + currentStepClass).length > 0 ) {
                        $('.' + currentStepClass).show();
                        scrollToForm();
                        if ( $('.' + currentStepClass).hasClass('last-step') ) {
                           $( '.direct-mail-form input[type="submit"]' ).val('Submit');
                        }
                     } else if ( $currentStep.next().hasClass('thank-you') || $currentStep.next().next().hasClass('thank-you') ) { // We have reached the end of the form
                        $(".direct-mail-form input[type='submit']").hide();
                        $( '.direct-mail-form .step-loader' ).css('display', 'inline-block');

                        // Submit the data...duh
                        $.ajax({
                           type: "POST",
                           crossDomain: true,
                           dataType: "jsonp",
                           url: $( form ).attr('action'),
                           data: $( form ).serialize()
                        }).always( function(data, textStatus, jqXHR){
                           $( '.direct-mail-form .step-loader' ).hide();
                           $( '.direct-mail-form input[type="submit"]' ).hide();

                           // For FHA Mortgage Reduction and VA Mortgage Reduction, redirect to thank you page
                           if ( $('body').hasClass('refifha') || $('body').hasClass('refiva') ) {
                              window.location.href = window.location.pathname + "thank-you";
                           } else {
                              $( '.direct-mail-form div.thank-you' ).fadeIn(200);
                           }
                        }).fail( function(jqXHR, textStatus, errorThrown){
                        });
                     }
                  }
               });
            }
            break;
         case 'newsletter_form':
            if ( $('.newsletter-form').length > 0 ) {
               $('.newsletter-form').nlcValidate({
                  // debug: true,
                  submitHandler: function( form ) {
                     $( '#newsletter_form' ).add('.parSignUp').add('.par-contact-shortly').hide();
                     $( '#newsletter_form .step-loader' ).css('display', 'inline-block');

                     $.ajax({
                        type: "POST",
                        crossDomain: true,
                        dataType: "jsonp",
                        url: $( form ).attr('action'),
                        data: $( form ).serialize()
                     }).always( function(data, textStatus, jqXHR){
                        $( '.step-loader' ).hide();
                        $( '.newsletter.thank-you' ).fadeIn(200);
                     }).fail( function(jqXHR, textStatus, errorThrown){
                     });
                  }
               });
            }
            break;
         case 'download-form':
            if ( $('.download-form').length > 0 ) {
               $('.download-form').nlcValidate({
                  // debug: true,
                  submitHandler: function( form ) {
                     $( '#download-form' ).add('.parSignUp').add('.par-contact-shortly').hide();
                     $( '#download-form .step-loader' ).css('display', 'inline-block');
                     

                     $.ajax({
                        type: "POST",
                        crossDomain: true,
                        dataType: "jsonp",
                        url: $( form ).attr('action'),
                        data: $( form ).serialize()
                     }).always( function(data, textStatus, jqXHR){
                        console.log("ajax submit");
                        $( '.step-loader' ).hide();
                        $( '#download-form .thank-you' ).fadeIn(200);

                        //global variable so that I can call it in main.js around line 969.
                        var globalFormSuccess = 'formSubmitted';

                        //window.open('https://info.nlcloans.com/Global/FileLib/NLC/VA_guide-pdf_download.pdf', '_blank');

                        
                        //window.location = '/wp-content/themes/nlc_custom/lib/get-download-pdf.php';
                        window.location = "https://info.nlcloans.com/va-guide/pre-qual-34932R-36844C9.html";
                        return false;
                     }).fail( function(jqXHR, textStatus, errorThrown){
                     });
                  }
               });
            }
            break;
         case 'branch_form':
            // Branch forms
            if ( $('.branch-form').length > 0 ) {
               $( '.branch-form' ).nlcValidate({
                  multistep: true,
                  submitHandler: function( form ) {
                     var $currentStep = $( form ).find('div.block-step').filter( function( index, element ){
                        return $( element ).css('display') === 'block';
                     });
                     var stepNumber = parseInt( $currentStep.attr('class').split(' ')[0].slice(-1) );

                     // Obtain the next step's class
                     currentStepClass = "step" + (stepNumber + 1);

                     // Hide the current step and then either show the next step or thank you page
                     $currentStep.hide();

                     if ( $('.' + currentStepClass).length > 0 ) {

                        $('.' + currentStepClass).show();
                        scrollToForm();
                        if ( $('.' + currentStepClass).hasClass('last-step') ) {
                           $( 'input[type="submit"]' ).val('Submit');
                        }
                     } else if ( $currentStep.next().hasClass('thank-you') || $currentStep.next().next().hasClass('thank-you') ) { // We have reached the end of the form

                        $("input[type='submit']").hide();
                        $( '.step-loader' ).css('display', 'inline-block');

                        // Submit the data...duh
                        $.ajax({
                           type: "POST",
                           crossDomain: true,
                           dataType: "jsonp",
                           url: $( form ).attr('action'),
                           data: $( form ).serialize()
                        }).always( function(data, textStatus, jqXHR){
                           $( '.step-loader' ).hide();
                           $( 'input[type="submit"]' ).hide();

                           $( 'div.thank-you' ).fadeIn(200);
                        }).fail( function(jqXHR, textStatus, errorThrown){
                        });
                     }
                  }
               });
            }
            break;
         case 'request_callback_partner_form':
            // Branch forms
            if ( $('#request_callback_partner_form').length > 0 ) {
               $( '#request_callback_partner_form' ).nlcValidate({
                  multistep: true,
                  submitHandler: function( form ) {
                     var $currentStep = $( form ).find('div.block-step').filter( function( index, element ){
                        return $( element ).css('display') === 'block';
                     });
                     var stepNumber = parseInt( $currentStep.attr('class').split(' ')[0].slice(-1) );

                     // Obtain the next step's class
                     currentStepClass = "step" + (stepNumber + 1);

                     // Hide the current step and then either show the next step or thank you page
                     $currentStep.hide();

                     if ( $('.' + currentStepClass).length > 0 ) {

                        $('.' + currentStepClass).show();
                        scrollToForm();
                        if ( $('.' + currentStepClass).hasClass('last-step') ) {
                           $( 'input[type="submit"]' ).val('Submit');
                        }
                     } else if ( $currentStep.next().hasClass('thank-you') || $currentStep.next().next().hasClass('thank-you') ) { // We have reached the end of the form

                        $("input[type='submit']").hide();
                        $( '.step-loader' ).css('display', 'inline-block');

                        // Submit the data...duh
                        $.ajax({
                           type: "POST",
                           crossDomain: true,
                           dataType: "jsonp",
                           url: $( form ).attr('action'),
                           data: $( form ).serialize()
                        }).always( function(data, textStatus, jqXHR){
                           $( '.step-loader' ).hide();
                           $( 'input[type="submit"]' ).hide();

                           $( 'div.thank-you' ).fadeIn(200);
                        }).fail( function(jqXHR, textStatus, errorThrown){
                        });
                     }
                  }
               });
            }
            break;
         case 'request_callback_form':
            // Contact Us form
            if ( $('#request_callback_form').length > 0 ) {
               $( '#request_callback_form' ).nlcValidate({
                  // debug: true,
                  submitHandler: function( form ) {
                     $('.step1').hide();

                     $("input[type='submit']").hide();
                     $( '.step-loader' ).css('display', 'inline-block');
                     // Send ajax POST to Eloqua
                     $.ajax({
                        type: "POST",
                        crossDomain: true,
                        dataType: "jsonp",
                        url: $( form ).attr('action'),
                        data: $( form ).serialize()
                     }).always( function(data, textStatus, jqXHR){
                        // Hide Step 1 and show the thank you section
                        $( '.step-loader' ).hide();
                        $('input[type="submit"]').hide();
                        $('.thank-you').show();
                     }).fail( function(jqXHR, textStatus, errorThrown){
                        $( '.step-loader' ).hide();
                        $('input[type="submit"]').hide();
                        $('.thank-you').show();
                     });
                  }
               });
            }
            break;
         default:
            break;
      }
   }
})(jQuery);
