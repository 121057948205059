(function($) {
/****** Loan Options Pages ******/
if ( window.location.pathname.indexOf('loan-options') >= 0 && $('#menu-loan-option-page-sidebar').length > 0 ) {

   $('#menu-loan-option-page-mobile-navigation').addClass('menu-loan-option-page-sidebar');
   $('.lo-info-container').find('#menu-loan-option-page-sidebar').addClass('menu-loan-option-page-sidebar');

   $('.menu-loan-option-page-sidebar > li').each(function(index, element){
      if ( /(^|\s)icon\-/.test($(element).attr("class")) ) {
         // Get the icon class name
         var match = $(element).attr("class").match(/(icon\-[^\s+]+)/gi);
         $( '<span class="' + match[0] + '"></span>' ).prependTo($(element));
         $(element).removeClass( match[0] );
      } else if ( $(element).hasClass('fa') ) {
         var secMatch = $(element).attr("class").match(/(fa[^\s+]*)/gi);
         $( '<span class="' + secMatch.join(' ') + '"></span>' ).prependTo($(element));
         $(element).removeClass( secMatch.join(' ') );
      }
   });

   var contactHref = $('#menu-loan-option-page-mobile-navigation .lop-contact').find(' > a ').hide().attr('href');
   $('#menu-loan-option-page-mobile-navigation .lop-contact').find('span').wrap('<a href="' + contactHref + '"></a>');

   var chatHref = $('#menu-loan-option-page-mobile-navigation .lop-chat-online').find(' > a ').hide().attr('href');
   $('#menu-loan-option-page-mobile-navigation .lop-chat-online').find('span').wrap('<a href="' + chatHref + '"></a>');

   var subscribeHref = $('#menu-loan-option-page-mobile-navigation .lop-subscribe').find(' > a ').hide().attr('href');
   $('#menu-loan-option-page-mobile-navigation .lop-subscribe').find('span').wrap('<a href="' + subscribeHref + '"></a>');

   // $('#menu-loan-option-page-mobile-navigation li:last-child > a').text('').append('<img class="img-responsive" src="https://nlcloans.com/wp-content/uploads/2015/05/thinkreddoor_logo.png" /><span class="fa fa-caret-right"></span>');

   // Convert any tables to a responsive table
   $('.lo-info-container article table').addClass('table').wrap('<div class="table-responsive"></div>');

   // Add the recent posts widget as a menu item to the sidebar
   var recentPostsWidget = $('.rpwe_widget-4.recent-posts-extended').detach();
   var listItem = $('<li class="trd-post menu-item menu-item-type-custom menu-item-object-custom menu-item-3847"></li>');
   $('#menu-loan-option-page-sidebar').append(listItem).find('li:last-child').append(recentPostsWidget);
   recentPostsWidget = null;

   var nHref = $('#menu-loan-option-page-sidebar').find('li:last-child').find('li.rpwe-li > a').attr('href');
   $('#menu-loan-option-page-sidebar').find('li:last-child').css('cursor', 'pointer').on('click', function(){
      var $this = $(this);
      var href = $this.find('li.rpwe-li > a').attr('href');
      window.location.href = href;
   });

   $('#menu-loan-option-page-sidebar .rpwe_widget-4').before('<img class="img-responsive" src="https://nlcloans.com/wp-content/uploads/2015/05/thinkreddoor_logo.png"><a href="' + nHref + '">Read More <span class="fa fa-caret-right"></span></a>');

   //var postDate = $('#menu-loan-option-page-sidebar .rpwe-time').text();
   //postDate = postDate.split(/\s+|,\s+/);
   var monthMapping = {
      "January": "01",
      "February": "02",
      "March": "03",
      "April": "04",
      "May": "05",
      "June": "06",
      "July": "07",
      "August": "08",
      "September": "09",
      "October": "10",
      "November": "11",
      "December": "12"
   };
   var padNumber = function(num) {
      return ('0' + num).slice(-2);
   };
   // var spanString = '<span class="post-month">' + monthMapping[postDate[0]] + '</span><span class="post-day">' + padNumber(postDate[1]) + '</span><span class="post-year">' + postDate[2].substring(2) + '</span>';
   // $('#menu-loan-option-page-sidebar .rpwe-time').html(spanString);

   $(window).scroll(function () {

      var scroll = $(this).scrollTop();
      var calc = scroll + $('#page_nav').outerHeight() + $('.sticky-banner.cloned').outerHeight();
      var calc2 = $('.loan-page-nav').offset().top + $('.loan-page-nav').outerHeight();
      var calc3 = $('.hero-section').offset().top + $('.hero-section').outerHeight() + $('.sticky-banner.cloned').outerHeight() + $('.loan-page-nav').outerHeight();
      var sidebarOffset = $('#menu-loan-option-page-sidebar').offset().top,
      sidebarHeight = $('#menu-loan-option-page-sidebar').outerHeight();

      if ( calc >= sidebarOffset - 20 && !($('#menu-loan-option-page-sidebar').hasClass('at-footer')) ) {
         $('#menu-loan-option-page-sidebar').addClass('stuck');
      }

      if ( sidebarOffset <= calc2 + 28 ) {
         $('#menu-loan-option-page-sidebar').removeClass('stuck at-footer');
      }

      // If the sticky menu goes below the height of the main content, set the position absolute
      // Make sure the navigation doesn't go below the bottom by more than 1px
      var mainContentHeight = $('.lo-info-container').offset().top + $('.lo-info-container').outerHeight();
      var finalNavPosition = mainContentHeight - $('#menu-loan-option-page-sidebar').outerHeight();

      if ( (sidebarOffset + sidebarHeight >= $('.lo-info-container').offset().top + $('.lo-info-container').outerHeight()) && !($('#menu-loan-option-page-sidebar').hasClass('at-footer')) ) {
         $('#menu-loan-option-page-sidebar').css({
            position: 'absolute',
            top: finalNavPosition + 'px'
         }).removeClass('stuck').addClass('at-footer');
      } else if ( $('#menu-loan-option-page-sidebar').hasClass('at-footer') && ($('.sticky-banner.cloned').offset().top + $('.sticky-banner.cloned').outerHeight() <= $('#menu-loan-option-page-sidebar').offset().top - 20) ) {
         $('#menu-loan-option-page-sidebar').attr('style', '').removeClass('at-footer').addClass('stuck');
      }
   });
}
})(jQuery); // Fully reference jQuery after this point.
