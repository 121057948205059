(function($){
   /*****
   * NLC Branch Phone Number switch
   *****/
   window.branchMetaInfo = {
      'Baton Rouge': ['225-238-1604', '470'],
      'Bowling Green': ['859-446-7525', '229'],
      'Brentwood': ['615-567-7663', '441'],
      'Carrollton': ['855-737-7283', '468'],
      'Columbus': ['855-951-7793', '442'],
      'Crofton': ['443-201-2119', '415'],
      'Crofton2': ['407-430-6277', '495'],
      'Denville': ['', '541'],
      'Fairlawn South': ['866-748-3885', '219'],
      'Ohio Purchase Team': ['877-816-1220', '221'],
      'Folsom' : ['610-674-6767', '443'],
      'Hollidaysburg': ['814-317-0046', '215'],
      'Honolulu': ['808-800-3606', '496'],
      'Independence': ['877-480-8050', '455'],
      'NEO Purchase Team': ['216-503-1662', '214'],
      'Indian Trail': ['828-290-4762', '444'],
      'Irving': ['855-737-2783', '340'],
      'Jacksonville': ['904-930-4200', '533'],
      'Lafayette': ['337-806-9950', '225'],
      'Lake Charles': ['337-580-5020', '494'],
      'Lake City': ['386-755-9000', '250'],
      'Longwood': ['407-770-5959', '542'],
      'Marlton': ['856-797-3434', '232'],
      'McAllen': ['956-340-4400', '341'],
      'RGV': ['956-467-4414', '454'],
      'Medina': ['866-759-0059', '222'],
      'Middleburg Hts': ['877-771-8082', '224'],
      'Middleburg Heights South': ['877-816-1220', '445'],
      'Moorestown': ['888-443-2901', '233'],
      'Nashville': ['615-732-2857', '213'],
      'Orlando': ['407-270-8502', '230'],
      'Orlando North': ['407-330-7777', '446'],
      'Oxnard': ['805-200-2450', '447'],
      'Parma': ['877-909-4585', '218'],
      'Reisterstown': ['410-206-1212', ''],
      'RGV': ['956-467-4414', '454'],
      'Round Rock': ['512-645-3269', '341'],
      'San Antonio': ['210-445-7823', '343'],
      'Southfield': ['313-310-3030', '497'],
      'Springfield': ['614-493-7116', '543'],
      'St Petersburg': ['727-498-3445', '211'],
      'Sulphur Springs': ['903-689-4388', '342'],
      'Towson': ['410-779-9433', '440'],
      'Tucker': ['770-212-2360', '220'],
      'Upper Arlington': ['614-330-8965', '532'],
      'Warrensville': ['877-292-0098', '226']
   };

   $.each(branchMetaInfo, function(key, value){
      var temp_key = key.toLowerCase().replace(/ /g, "");
      if ( window.location.hostname.split('.')[0] === temp_key ) {
         var temp_pn = value[0].replace(/-/g, '');
         $('#click_call > a').attr('href', 'tel:' + temp_pn);
         $('#click_call > a').text(value[0]);
      }
   });


   /*********************************
   * NLC COOKIE TRACKING
   ********************************/
   var branchName = window.location.hostname.split('.')[0],
   branchKeys = Object.keys(branchMetaInfo);

   modbranchKeys = $.map(branchKeys, function(element, index){
      return element.toLowerCase().replace(/ /g, "");
   });

   if ( modbranchKeys.indexOf(branchName) > -1 ) {
      // Add a URL parameter for referring_branch to every link out to NLC Loans
      $('a').each(function(){
         var $this = $(this);
         var href = "";
         if ( $this && $this.attr('href') != undefined )
         href = $.trim($this.attr('href'));

         if ( href.indexOf('https://nlcloans.com') == 0 || href.indexOf('http://nlcloans.com') == 0 || href.indexOf('http://dev.nlcloans.com') == 0 ) {
            // We're dealing with a direct link to nlcloans.com
            if ( href.split('?').length > 1 ) {
               // There were already parameters attached to the URL
               // So we add ours with an ampersand
               $this.attr('href', href + "&referring_branch=" + encodeURIComponent(branchKeys[modbranchKeys.indexOf(branchName)]));
            } else {
               // There were no initial parameters, add ours with a question mark
               // Check to see if there's already a slash at the end of the URL
               if ( href.charAt(href.length - 1) === "/")
               href += "?referring_branch=" + encodeURIComponent(branchKeys[modbranchKeys.indexOf(branchName)]);
               else
               href += "/?referring_branch=" + encodeURIComponent(branchKeys[modbranchKeys.indexOf(branchName)]);

               $this.attr('href', href);
            }
         }
      });
   }

   /* NLC Apply Now link swap */
   var hostname = window.location.hostname.split('.')[0];

   if ( hostname === 'nlcloans' ) {
      var queryString = getQueryParameters();
      if ( queryString.referring_branch && queryString.referring_branch !== undefined && queryString.referring_branch !== 'undefined' && queryString.referring_branch !== "" ) {
         if ( supportsHTML5Storage() && !sessionStorage.referring_branch ) {
            sessionStorage.referring_branch = queryString.referring_branch;
         }
      }

      if ( (queryString['referring_branch'] && queryString['referring_branch'] != undefined && queryString['referring_branch'] != 'undefined' && queryString['referring_branch'] !== "") ) {
         var newApplyLink = "https://" + queryString['referring_branch'].toLowerCase().replace(/[\s\%20]+/g, "") + ".nlcloans.com/apply/";
         $( '#menu-primary-navigation a' ).filter( function(index, element){
            return $(element).text() === "Apply Now";
         }).attr('href', newApplyLink);
      }

      if ( sessionStorage['referring_branch'] !== null && sessionStorage['referring_branch'] !== "" && sessionStorage['referring_branch'] != undefined ) {
         var newApplyLink = "https://" + sessionStorage['referring_branch'].toLowerCase().replace(/[\s\%20]+/g, "") + ".nlcloans.com/apply/";
         $( '#menu-primary-navigation a' ).filter( function(index, element){
            return $(element).text() === "Apply Now";
         }).attr('href', newApplyLink);
      }
   }

})(jQuery);
