/**
 * NLC Form Validation plugin.
 * Author: Nikhil Venkatesh
 * Date: October 8, 2014
 * Description: Used to validate any form in the NLC website.
 * Usage:
 *	Default - $('#formid').nlcValidate();
 *	With options -
 		$('#formid').nlcValidate({
 		});
 **/
/****************************************************
 Documentation
****************************************************/
/*
Example HTML markup:
<form class="block-form" action="/" method="POST">
	<div class="inline-form-field col-xs-12 col-sm-12">
		<div class="cs-select cs-skin-border" tabindex="0" data-name="mortgagegoals">
			<span class="cs-placeholder">Select Mortgage Goal</span>
			<div class="cs-options">
				<ul>
					<li data-option="" data-value="Refinance - Monthly Savings">
						<span>Refinance - Monthly Savings</span>
					</li>
					<li data-option="" data-value="Refinance - Pay Off Home Faster">
						<span>Refinance - Pay Off Home Faster</span>
					</li>
					<li data-option="" data-value="Refinance - Cash Out">
						<span>Refinance - Cash Out</span>
					</li>
					<li data-option="" data-value="Refinance - Debt Reduction">
						<span>Refinance - Debt Reduction</span>
					</li>
					<li data-option="" data-value="Purchase - Ready to Buy">
						<span>Purchase - Ready to Buy</span>
					</li>
					<li data-option="" data-value="Purchase - 2 to 3 Months">
						<span>Purchase - 2 to 3 Months</span>
					</li>
					<li data-option="" data-value="Purchase - Researching Options">
						<span>Purchase - Researching Options</span>
					</li>
				</ul>
			</div>
			<select class="cs-select cs-skin-border" name="mortgagegoals">
				<option value="" disabled="" selected="">Select Mortgage Goal</option>
				<option value="Refinance - Monthly Savings">Refinance - Monthly Savings</option>
				<option value="Refinance - Pay Off Home Faster">Refinance - Pay Off Home Faster</option>
				<option value="Refinance - Cash Out">Refinance - Cash Out</option>
				<option value="Refinance - Debt Reduction">Refinance - Debt Reduction</option>
				<option value="Purchase - Ready to Buy">Purchase - Ready to Buy</option>
				<option value="Purchase - 2 to 3 Months">Purchase - 2 to 3 Months</option>
				<option value="Purchase - Researching Options">Purchase - Researching Options</option>
			</select>
		</div>
	</div>
</form>
*/

;(function ( $, window, document, undefined ) {

	// Revised plugin method
	$.extend( $.fn, {
		nlcValidate: function( options ) {
			if ( !this.length ) {
				if ( options && options.debug && window.console ) {
					console.warn( "Nothing selected, can't validate the form - returning nothing." );
				}
			}

			// Prevent against multiple instantiations by checking to see if a validator already exists
			var validator = $.data( this[ 0 ], 'plugin_nlcValidate' );
			if ( validator ) { return validator; }

			// Add novalidate tag if HTML5 -- needed to ignore the required fields in the form
			// so the browser doesn't handle them
			this.attr( "novalidate", "novalidate" );

			// Create the new object, expose this object so that it can be modified without modifying source
			validator = new $.nlcValidator( this[ 0 ], options );
			$.data( this[ 0 ], "plugin_nlcValidate", validator );

			// Check to make sure the user wants to submit the form, defaulted to true
			if ( validator.settings.onsubmit ) {
				// Case 1: There is a submit button or button used to submit the form
				this.validateDelegate( ":submit", "click", function( event ) {
					if ( validator.settings.submitHandler ) {
						validator.submitButton = event.target;
					}
					// Allow suppressing form submission using either a cancel class or a formnovalidate attribute
					if ( $( event.target ).hasClass( "cancel" ) || $( event.target ).attr( "formnovalidate" ) !== undefined ) {
						validator.cancelSubmit = true;
					}
				});

				// Case 2: There is a fake button using a hyperlink with href
				this.validateDelegate( validator.settings.submitButton, "click", function( event ) {
					validator.submitButton = event.target;
				});

				// Here is where the validation occurs on submit
				this.submit( function( event ) {
					if ( validator.settings.debug ) {
						event.preventDefault();		// Prevent form from submitting to see console output
					}

					// Inner function to handle custom submitHandler functionality
					function handle() {
						var hidden, result;
						if ( validator.settings.submitHandler ) {
							if ( validator.submitButton ) {
								// Insert hidden input as missing submit button replacement - don't know if I need this
								hidden = $( "<input type='hidden'/>")
											.attr( "name", validator.submitButton.name )
											.val( validator.submitButton.value )
											.appendTo( validator.currentForm );
							}
							result = validator.settings.submitHandler.call( validator, validator.currentForm, event );
							if ( validator.submitButton ) {
								hidden.remove();
							}
							if ( result !== undefined ) {
								return result;
							}
							return false;
						}
						return true;
					}

					// Prevent submit for invalid forms or custom submit handlers
					if ( validator.cancelSubmit ) {
						validator.cancelSubmit = false;
						return handle();
					}

					if ( validator.checkFormValidity() ) {
						return handle();
					} else {
						// Form was not valid, add the classes and CSS styles to the invalid elements
						validator.focusInvalid();
						return false;
					}

				});
			}
		},
		// Allows for event delegation to be called when the event.target matches the delegate specified
		validateDelegate: function( delegate, type, handler ) {
			return this.on( type, function( event ){
				var target = $( event.target );
				if ( target.is(delegate) ) {
					handler.apply( target, arguments );
				}
			});
		}
	});


	/*******************************************************************************************************
	* The actual plugin validator constructor.  Exposed so different methods and defaults can be overridden.
	******************************************************************************************************/
	$.nlcValidator = function( form, options ) {
		this.settings = $.extend( true, {}, $.nlcValidator.defaults, options );
		this.currentForm = form;
		this.init();
	};

	// Extend the validator with prototype and it's methods, and other methods directly attached to plugin
	$.extend( $.nlcValidator, {
		defaults: {
			debug: false,
			onsubmit: true,
			ignore: "",
			multistep: false,
			submitButton: '.nlc-formsubmit',
			validClass: "nlc-valid",
			errorClass: "nlc-error",
			highlight: function( element, errorClass, validClass ) {
				if ( element.type === "radio" ) {
					$( this.currentForm ).find( "[name='" + element.name + "']" ).addClass( errorClass ).removeClass( validClass );
				} else {
					$( element ).addClass( errorClass ).removeClass( validClass );
				}
			},
			unhighlight: function( element, errorClass, validClass ) {
				if ( element.type === "radio" ) {
					$( this.currentForm ).find( "[name='" + element.name + "']" ).removeClass( errorClass ).addClass( validClass );
				} else {
					$( element ).removeClass( errorClass ).addClass( validClass );
				}
			}
		},
		prototype: {
			init: function() {
				// Reset the form - set all the variables we are using during processing.
				this.reset();

				function delegate( event ) {
					var validator = $.data( this[ 0 ].form, "plugin_nlcValidate" ),
						eventType = "on" + event.type.replace( /^validate/, "" ),
						settings = validator.settings;
					if ( settings[ eventType ] && !this.is( settings.ignore ) ) {
						settings[ eventType ].call( validator, this[ 0 ], event );
					}
				}
				$( this.currentForm )
					.validateDelegate( ":text, [type='password'], [type='file'], select, textarea, " +
						"[type='number'], [type='search'] ,[type='tel'], [type='url'], " +
						"[type='email'], [type='datetime'], [type='date'], [type='month'], " +
						"[type='week'], [type='time'], [type='datetime-local'], " +
						"[type='range'], [type='color'], [type='radio'], [type='checkbox']",
						"focusin focusout keyup", delegate)
					// Support: Chrome, oldIE
					// "select" is provided as event.target when clicking a option
					.validateDelegate("select, option, [type='radio'], [type='checkbox']", "click", delegate);

				if ( this.settings.invalidHandler ) {
					$( this.currentForm ).bind( "invalid-form.validate", this.settings.invalidHandler );
				}

				// Add aria-required to any Static/Data/Class required fields before first validation
				// Screen readers require this attribute to be present before the initial submission http://www.w3.org/TR/WCAG-TECHS/ARIA2.html
				$( this.currentForm ).find( "[required], [data-rule-required], .required" ).attr( "aria-required", "true" );
			},
			/**************************************************
			* Method that accumulates all the errors in the form
			* to highlight/display afterwards.
			**************************************************/
			addError: function( element, rule ) {
				this.errorList.push({
					element: element,
					method: rule.method
				});
			},
			/**************************************************
			* Method that kicks everything off - called in the
			* jQuery plugin nlcValidate method, checks form by
			* looping through each of the elements and performing
			* individual checks on each element.
			**************************************************/
			checkFormValidity: function() {
				this.reset();	// Probably not needed
				for ( var i = 0, elements = ( this.currentElements = this.getFormElements() ); elements[i]; i++ ) {
					this.checkIndividualElement( elements[i] );
				}
				this.showErrors();
				return this.valid();
			},
			/**************************************************
			* Validates an individual element passed to it.
			* This means that the particular field meets all the
			* criteria and rules assigned to it.
			**************************************************/
			checkIndividualElement: function( element ) {
				element = this.validationTargetFor( this.clean( element ) );

				var rules = {},
						val = this.elementValue( element ),
						result, method, rule;

				// Combine all the attributes and classes that correspond with the outlined methods
				$.extend( rules, $.nlcValidator.attributeRules( element ), $.nlcValidator.classRules( element ) );

				// console.log( rules );

				// Now loop through the rules and use the methods to perform the validations
				for ( method in rules ) {
					rule = { method: method, parameters: rules[ method ] };

					try {
						result = $.nlcValidator.methods[ method ].call( this, val, element, rule.parameters );

						if ( !result ) {
							this.addError( element, rule );
							return false;
						}
					} catch( e ) {
						if ( this.settings.debug && window.console ) {
							console.log( "Exception occurred when checking element " + element.id + ", check the '" + rule.method + "' method.", e );
						}
						throw e;
					}
				}
			},
			/**************************************************
			* A useful method to determine if the element we are
			* currently processing is a checkbox group or radio
			* group.  The validation and error handling is
			* slightly different for these.
			**************************************************/
			checkOrRadio: function ( element ) {
				return ( /radio|checkbox/i ).test( element.type );
			},
			/**************************************************
			* Method to reset all the form validation variables.
			**************************************************/
			clean: function( selector ) {
				return $( selector )[ 0 ];
			},
			/**************************************************
			* Method to reset all the form validation variables.
			**************************************************/
			focusInvalid: function() {
				console.log( this.errorList );
				// try {
				// 	$( this.errorList.length && this.errorList[ 0 ].element || [] )
				// 		.filter( ":visible" )
				// 		.focus()
				// 		.trigger( "focusin" );
				// } catch( e ) {
				// 	// ignore IE throwing errors when focusing hidden elements
				// }
			},
			/**************************************************
			* Method to get the number of selected options or
			* checked boxes/radio buttons.
			**************************************************/
			getLength: function( value, element ) {
				switch( element.nodeName.toLowerCase() ) {
					case "select":
						if ( $( "option:selected", element ).length ) {
							return $( "option:selected", element ).length;
						}
						break;
					case "input":
						if ( this.checkOrRadio( element ) ) {
							return $( this.currentForm ).find( "[name='" + element.name + "']" ).filter( ":checked" ).length;
						}
						break;
				}
				return value.length;
			},
			/**************************************************
			* Method to retrieve all the elements we are
			* interested in from the form - all required, anything
			* with attributes that match methods, etc.
			**************************************************/
			getFormElements: function() {
				var validator = this, rulesCache = {};

				// Select all the valid inputs inside the form ( no submit or reset buttons )
				if ( this.settings.multistep ) {
					return $( this.currentForm ).find('.block-step').filter( function(index, element){
						return $( element ).css('display') === "block";
					}).find( "input, select, textarea" )
							.not( ":submit, :reset, :image, .nlc-formsubmit, [disabled], [readonly]" )
							.not( this.settings.ignore )
							.filter( function() {
								// Use a cache to make sure we only select the first element for each name
								if ( !this.name && validator.settings.debug && window.console ) {
									console.error( "%o has no name assigned", this );
								}

								if ( this.name in rulesCache ) { return false; }

								rulesCache[ this.name ] = true;
								return true;
							});
				} else {
					return $( this.currentForm )
							.find( "input, select, textarea" )
							.not( ":submit, :reset, :image, .nlc-formsubmit, [disabled], [readonly]" )
							.not( this.settings.ignore )
							.filter( function() {
								// Use a cache to make sure we only select the first element for each name
								if ( !this.name && validator.settings.debug && window.console ) {
									console.error( "%o has no name assigned", this );
								}

								if ( this.name in rulesCache ) { return false; }

								rulesCache[ this.name ] = true;
								return true;
							});
				}
			},
			/**************************************************
			* Useful method that abstracts out getting the value
			* from a form field.
			**************************************************/
			elementValue: function( element ) {
				var val,
						$element = $( element ),
						type = element.type.toLowerCase();

				// Look at HTML5 Constraint Validation API
				// The .validity object has following properties:
				// .valid, .valueMissing, .typeMismatch, ... .badInput, .customError
				// http://www.sitepoint.com/html5-forms-javascript-constraint-validation-api/

				if ( type === "radio" || type === "checkbox" ) {
					return $( "input[name='" + element.name + "']:checked" ).val();
				} else if ( type === "number" && typeof element.validity !== "undefined" ) {
					// Returns true if the entry CANNOT be converted to a value
					return element.validity.badInput ? false : $element.val();
				}

				val = $element.val();
				if ( typeof val === "string" ) {
					return val.replace( /\r/g, "" );
				}

				return val;
			},
			/**************************************************
			* Method to reset all the form validation variables.
			**************************************************/
			reset: function() {
				this.successList = [];
				this.errorList = [];
				this.errorMap = {};
				this.toShow = $( [] );
				this.toHide = $( [] );
				this.currentElements = $( [] );
			},
			/**************************************************
			* Method that actually shows the error (using the
			* error class specified by user).
			**************************************************/
			showErrors: function() {
				var i, elements, error;

				if ( this.errorList.length > 0 ) {
					for ( i = 0; this.errorList[ i ]; i++ ) {
						error = this.errorList[ i ];

						if ( this.settings.highlight ) {
							// Hack to get the custom select to get the error class
							if ( error.element.className.indexOf( "cs-select" ) >= 0 ) {
								this.settings.highlight.call( this, error.element.parentNode, this.settings.errorClass, this.settings.validClass );
							} else {
								this.settings.highlight.call( this, error.element, this.settings.errorClass, this.settings.validClass );
							}
						}
					}

					// Unhighlight the valid fields
					if ( this.settings.unhighlight ) {
						for ( i = 0, elements = this.validElements(); elements[ i ]; i++ ) {
							// Hack to get the custom select to get the error class
							if ( elements[ i ].className.indexOf( "cs-select" ) >= 0 ) {
								this.settings.unhighlight.call( this, elements[ i ].parentNode, this.settings.errorClass, this.settings.validClass );
							} else {
								this.settings.unhighlight.call( this, elements[ i ], this.settings.errorClass, this.settings.validClass );
							}
						}
					}
				} else {

				}
			},
			/**************************************************
			* Method that makes sure we only select one element
			* when selecting using name.
			**************************************************/
			validElements: function() {
				return this.currentElements.not( this.invalidElements() );
			},
			/**************************************************
			* Method that makes sure we only select one element
			* when selecting using name.
			**************************************************/
			invalidElements: function() {
				return $( this.errorList ).map( function(){
					return this.element;
				});
			},
			/**************************************************
			* Method that makes sure we only select one element
			* when selecting using name.
			**************************************************/
			validationTargetFor: function( element ) {
				if ( this.checkOrRadio( element ) ) {
					element = $( this.currentForm ).find( '[name="' + element.name + '"]' );
				}

				return $( element ).not( this.settings.ignore )[ 0 ];
			},
			/**************************************************
			* Final method to make sure everything checks out.
			**************************************************/
			valid: function() {
				return this.errorList.length === 0;
			}
		},
		/**************************************************
		* Methods for validating different types of fields.
		**************************************************/
		methods: {
			required: function( value, element ) {
				if ( element.nodeName.toLowerCase() === "select" ) {
					// could be an array for select-multiple or a string, both are fine this way
					var val = $( element ).val();
					return val && val.length > 0;
				}
				if ( this.checkOrRadio( element ) ) {
					return this.getLength( value, element ) > 0;
				}
				return $.trim( value ).length > 0;
			},

			email: function( value, element ) {
				// From http://www.whatwg.org/specs/web-apps/current-work/multipage/states-of-the-type-attribute.html#e-mail-state-%28type=email%29
				// If you have a problem with this implementation, report a bug against the above spec
				// Or use custom methods to implement your own email validation
				return /^[a-zA-Z0-9.!#$%&'*+\/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/.test( value );
			},

			url: function( value, element ) {
				// contributed by Scott Gonzalez: http://projects.scottsplayground.com/iri/
				return /^(https?|s?ftp):\/\/(((([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!\$&'\(\)\*\+,;=]|:)*@)?(((\d|[1-9]\d|1\d\d|2[0-4]\d|25[0-5])\.(\d|[1-9]\d|1\d\d|2[0-4]\d|25[0-5])\.(\d|[1-9]\d|1\d\d|2[0-4]\d|25[0-5])\.(\d|[1-9]\d|1\d\d|2[0-4]\d|25[0-5]))|((([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))\.)+(([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))\.?)(:\d*)?)(\/((([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!\$&'\(\)\*\+,;=]|:|@)+(\/(([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!\$&'\(\)\*\+,;=]|:|@)*)*)?)?(\?((([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!\$&'\(\)\*\+,;=]|:|@)|[\uE000-\uF8FF]|\/|\?)*)?(#((([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!\$&'\(\)\*\+,;=]|:|@)|\/|\?)*)?$/i.test( value );
			},

			date: function( value, element ) {
				return !/Invalid|NaN/.test( new Date( value ).toString() );
			},

			dateISO: function( value, element ) {
				return /^\d{4}[\/\-](0?[1-9]|1[012])[\/\-](0?[1-9]|[12][0-9]|3[01])$/.test( value );
			},

			number: function( value, element ) {
				return /^-?(?:\d+|\d{1,3}(?:,\d{3})+)?(?:\.\d+)?$/.test( value );
			},

			digits: function( value, element ) {
				return /^\d+$/.test( value );
			},

			minlength: function( value, element, param ) {
				var length = $.isArray( value ) ? value.length : this.getLength( value, element );
				return length >= param;
			},

			maxlength: function( value, element, param ) {
				var length = $.isArray( value ) ? value.length : this.getLength( value, element );
				return length <= param;
			},

			min: function( value, element, param ) {
				return value >= param;
			},

			max: function( value, element, param ) {
				return value <= param;
			},

			equalTo: function( value, element, param ) {
				var target = $( param );
				return value === target.val();
			},

			zipcode: function( value, element ) {
				return /^\d{5}(?:[-\s]\d{4})?$/.test( value );
			}
		},
		ruleSettings: {
			required: { required: true },
			email: { email: true },
			url: { url: true },
			date: { date: true },
			dateISO: { dateISO: true },
			number: { number: true },
			digits: { digits: true },
			zipcode: { zipcode: true }
		},
		/**************************************************
		* Method that checks the attributes of the element
		* and compares them against the ruleSettings.
		**************************************************/
		attributeRules: function( element ) {
			var rules = {},
					$element = $( element ),
					type = element.getAttribute( "type" ),
					method, value;

			for ( method in $.nlcValidator.methods ) {
				// Support for <input required> in both HTML5 and older browsers
				if ( method === "required" ) {
					value = element.getAttribute( method );

					// Some browsers return an empty string for the required attribute
					// or could have required="" markup in non-HTML5 browsers
					if ( value === "" ) { value = true; }

					// Force non-HTML5 browsers to return a bool
					value = !!value;
				} else {
					value = $element.attr( method );
				}

				// Convert the value to a number for number inputs, and for text for backwards
				// compatibility allows type="date" and others to be compared as strings
				if ( /min|max/.test( method ) && ( type === null || /number|range|text/.test( type ) ) ) {
					value = Number( value );
				}

				if ( value || value === 0 ) {
					rules[ method ] = value;
				} else if ( type === method && type !== "range" ) {
					rules[ method ] = true;
				}
			}

			// Maxlength might have -1, 2147483647 in IE and 524288 in Safari for text inputs
			if ( rules.maxlength && /-1|2147483647|524288/.test( rules.maxlength ) ) {
				delete rules.maxlength;
			}

			return rules;
		},
		/**************************************************
		* Method that checks the classes of the element
		* and compares them against the ruleSettings.
		**************************************************/
		classRules: function( element ) {
			var rules = {},
					classes = $( element ).attr( "class" );

			if ( classes ) {
				$.each ( classes.split(/\s+/), function(){
					if ( this in $.nlcValidator.ruleSettings ) {
						$.extend( rules, $.nlcValidator.ruleSettings[ this ] );
					}
				} );
			}

			return rules;
		}
	});

})( jQuery, window, document );
