/* ========================================================================
* DOM-based Routing
* Based on http://goo.gl/EUTi53 by Paul Irish
*
* Only fires on body classes that match. If a body class contains a dash,
* replace the dash with an underscore when adding it to the object below.
*
* .noConflict()
* The routing is enclosed within an anonymous function so that you can
* always reference jQuery with $, even when in .noConflict() mode.
*
* Google CDN, Latest jQuery
* To use the default WordPress version of jQuery, go to lib/config.php and
* remove or comment out: add_theme_support('jquery-cdn');
* ======================================================================== */


(function($) {

   $(window).scroll(function() {
       var scroll = $(window).scrollTop();
       if (scroll > 0) {
           $("#lp_head").addClass("active");
       }
       else {
           $("#lp_head").removeClass("active");
       }
   });

   $('.people-slider').slick({
     dots: true,
     infinite: true,
     slidesToShow: 4,
     slidesToScroll: 4,
     nextArrow: '<div class="next-button"><i class="fa fa-chevron-right" aria-hidden="true"></i></div>',
     prevArrow: '<div class="prev-button"><i class="fa fa-chevron-left" aria-hidden="true"></i></div>',
     responsive: [
       {
         breakpoint: 1024,
         settings: {
           slidesToShow: 3,
           slidesToScroll: 3,
         }
       },
       {
         breakpoint: 768,
         settings: {
           slidesToShow: 2,
           slidesToScroll: 2
         }
       },
       {
         breakpoint: 480,
         settings: {
           slidesToShow: 1,
           slidesToScroll: 1
         }
       }
       ]
   });

   $(window).scroll(function() {
       var scroll = $(window).scrollTop();
       if (scroll > 0) {
           $("#lp_head").addClass("active");
       }
       else {
           $("#lp_head").removeClass("active");
       }
   });

   // Use this variable to set up the common and page specific functions. If you
   // rename this variable, you will also need to rename the namespace below.
   var Sage = {
      // All pages
      'common': {
         init: function() {
            // JavaScript to be fired on all pages
            // Common functions
            window.getPostName = function() {
               // Now we have all of the URL parameters stored
               // Get the URL, remove the trailing slash
               var currentURL = window.location.href, postName = "";
               if ( currentURL.slice(-1) === '/' ) {
                  postName = currentURL.slice(0, -1).split('/');
               } else {
                  postName = currentURL.split('/');
               }

               // Get the post name at the end of the URL string
               postName = postName[3];
            };

            var queryString = getQueryParameters();

            if ( queryString.referring_branch && queryString.referring_branch !== undefined && queryString.referring_branch !== 'undefined' && queryString.referring_branch !== "" ) {
               if ( supportsHTML5Storage() ) {
                  sessionStorage.referring_branch = queryString.referring_branch;
               }
            }

            // General masking across site
            // set masks for certain input fields
            $('.phone-number').mask('000-000-0000');
            $('.date-of-birth').mask('00/00/0000');
            $('.tax-number').mask('000-00-0000');
            $('.zipcode').mask('00000');
            $('.moneyFormat').mask('$00000000');

            /* Clicking on search magnifying glass */
            $('.search-form-container').on('click', '.icon-Search-01', function(){
               var $searchForm = $(this).prev('#searchform'),
               inputText = $searchForm.find('#sinput').val();
               if ( inputText !== "" )
               $searchForm.submit();
            });

            // Selecting the loan type
            $( '.loan-type-button' ).on('click', function(event){
               event.preventDefault();
               $( '.loan-type-button' ).removeClass( 'active' );
               $( this ).addClass('active');
               // $( 'input[name="loantype"]' ).val( $(this).text() );
               // var loantype = $('input[name="loantype"]').val();

               var leadSource = "";
               // Get the type of loan option to send to Eloqua as LeadSource
               if ( $('input[name="leadSource"]').length > 0 ) {
                  leadSource = $('input[name="leadSource"]').val();
               } else {
                  var hrefSplit = window.location.href.split('/');
                  leadSource = hrefSplit[hrefSplit.length - 1] !== "" ? hrefSplit[hrefSplit.length - 1] : hrefSplit[hrefSplit.length - 2];
                  leadSource = leadSource.replace(/-/g, ' ');
               }

               var url = getProtocolandHost() + "pre-qualification-form/?loantype=" + encodeURIComponent($(this).text()) + "&leadSource=" + leadSource + "&step=2";
               window.location.href = url;
            });

            /* Set up the Personalized Quote Form */
            setupForm( 'free_mortgage_consultation_form', sessionStorage );
            /* Set up the Pre-Qualification Forms */
            setupForm( 'pre_qualification_form', sessionStorage );
            /* Set up the PDF Download Forms */
            setupForm( 'pdf_form', sessionStorage );
            /* Set up Direct Mail Forms */
            setupForm( 'direct_mail_form', sessionStorage );
            /* Set up Contact Form */
            setupForm( 'contact_us_form', sessionStorage );
            /* Set up Newsletter Form */
            setupForm( 'newsletter_form', sessionStorage );
            /* Set up Newsletter Form */
            setupForm( 'download-form', sessionStorage );

            /* Set up Branch Forms */
            setupForm( 'branch_form', sessionStorage );

            /* Set up Nations Branch Forms */
            setupForm( 'request_callback_partner_form' );
            setupForm( 'request_callback_form' );

            // Convert all hero sliders using FlexSlider
            $("#header_flexslider").flexslider({
               animation: "fade",
               animationSpeed: 1000,
               directionNav: false,
               controlNav: true,
               pauseOnHover: true,
               smoothHeight: true
            });

            // Load rotating blocks flexslider with options
            $(" .rotating-blocks-flexslider ").flexslider({
               animation: "slide",
               animationSpeed: 1000,
               directionNav: true,
               controlNav: false,
               pauseOnHover: true,
               smoothHeight: true
            });

            $('.savings-scenario-disclaim-link').fancybox({
               maxWidth	   : 650,
               fitToView	: true,
               width 		: 'auto',
               height		: 'auto',
               autoSize	   : true,
               closeClick	: false,
               openEffect	: 'none',
               closeEffect	: 'none'
            });

            /* Email Subscribe Lightbox functionality */
            $('.lop-subscribe').click(function(){
               $('body').scrollTop();
               window.scrollTo(0,0);
            });

            $('.lop-subscribe > a').fancybox({
               maxWidth	   : 450,
               fitToView	: true,
               width 		: 'auto',
               height		: 'auto',
               autoSize	   : false,
               closeClick	: false,
               openEffect	: 'none',
               closeEffect : 'none'
            });

            /* Full post click fix */
            // Create click handler for the entire gray box on posts
            $('.post-container-extras').click(function(){
               $(this).find('.read-more')[0].click();
            });

            /* Full click on CTA boxes */
            $('.cta-box').click(function(){
               $(this).find('.cta-box-button')[0].click();
            });

            /************************************
            * Sticky Banner scroll functionality
            *************************************/
            // Create a clone of the sticky baner, right next to the original
            if ( $('.sticky-banner') ) {
               $('.sticky-banner').addClass('original').clone().insertAfter('.sticky-banner').addClass('cloned').css({ 'position': 'fixed', 'top': '0', 'margin-top': '0', 'z-index': '1500' }).removeClass('original').hide();

               var stickIt = function() {
                  var orgElementPos = $('.original').offset();
                  if ( orgElementPos !== undefined ) {
                     orgElementTop = orgElementPos.top;

                     navbarHeight = $('#page_nav').outerHeight();

                     if ( $(window).scrollTop() >= (orgElementTop - navbarHeight) ) {
                        // We scrolled past the original position; now only show the cloned sticky banner.

                        // Cloned element should always have same left position and width as original element.
                        orgElement = $('.original');
                        coordsOrgElement = orgElement.offset();
                        leftOrgElement = coordsOrgElement.left;
                        widthOrgElement = orgElement.width();

                        $('.cloned').css({
                           'top': navbarHeight + 'px'
                        }).show();

                        $('.original').css('visibility', 'hidden');
                     } else {
                        // Not scrolled past the sticky banner; only show the original banner.
                        $('.cloned').hide();
                        $('.original').css('visibility', 'visible');
                     }

                  } // end undefined check
               }; // end stickIt function

               scrollIntervalID = setInterval( stickIt, 10 );
            }

            $('.lop-chat-online > a').on('click', function(event){
               event.preventDefault();

               if ( !detect_mobile_browser() ) {
                  $('#habla_window_state_div').removeClass('olrk-state-compressed').addClass('olrk-state-expanded');
                  $('#habla_compressed_div').hide();
                  $('#habla_expanded_div').show();
               }
            });

            // Set up the carousel for Meet the Team
            // Meet the Team page - Team member scrolling for the second row
            if ( $('.team-member-carousel').length > 0 ) {
               $('.team-member-carousel').slick({
                  dots: false,
                  infinite: true,
                  speed: 300,
                  slidesToShow: 5,
                  slidesToScroll: 5,
                  responsive: [
                     {
                        breakpoint: 1200,
                        settings: {
                           slidesToShow: 5,
                           slidesToScroll: 5
                        }
                     },
                     {
                        breakpoint: 992,
                        settings: {
                           slidesToShow: 3,
                           slidesToScroll: 3
                        }
                     },
                     {
                        breakpoint: 768,
                        settings: {
                           slidesToShow: 2,
                           slidesToScroll: 2
                        }
                     },
                     {
                        breakpoint: 480,
                        settings: {
                           slidesToShow: 1,
                           slidesToScroll: 1
                        }
                     }
                  ]
               });

               $('.meet-the-team-next').on('click', function(event){
                  event.preventDefault();
                  var $this = $(this),
                  url = $this.parent('.member-cta-button').prev().find('#team_selector option:selected').data('url');
                  if ( url && url !== "" ) {
                     window.location.href = url;
                  }
               });
            }
         },
         finalize: function() {
            // JavaScript to be fired on all pages, after page specific JS is fired
         }
      },
      // Home page
      'home': {
         init: function() {
            // JavaScript to be fired on the home page
         },
         finalize: function() {
            // JavaScript to be fired on the home page, after the init JS
         }
      },
      // Apply Now page (1003 form)
      'apply_now': {
         init: function() {
            // First thing we do is populate the loan officers dropdown
            // $.ajax({
            //    type: "GET",
            //    dataType: "json",
            //    url: "/wp-content/themes/nlc_custom/lib/get-loan-officers.php"
            // }).done( function(data, textStatus, jqXHR){
            //    var loanOfficers = JSON.parse(data['loan-officers']); // Array of loan officers
            //    var optionsString = '<option value="" disabled="" selected="">* Who is your current Personal Mortgage Advisor?</option>';
            //    optionsString += '<option value="bfeikle">I don\'t have one</option>';
            //
            //    var compare = function(a,b) {
            //       if ( a.FirstName < b.FirstName ) {
            //          return -1;
            //       }
            //       if ( a.FirstName > b.FirstName ) {
            //          return 1;
            //       }
            //       return 0;
            //    };
            //    loanOfficers = loanOfficers.sort(compare);
            //
            //    $.each(loanOfficers, function(index, loanOfficer){
            //       optionsString += '<option value="' + loanOfficer.ID + '">' + loanOfficer.FullName + '</option>';
            //    });
            //
            //    $('select[name="preferredloanofficer_1612"]').html(optionsString);
            // }).fail( function(jqXHR, textStatus, errorThrown){
            //    console.log( errorThrown + ": " + textStatus );
            // });

            $('#form1003').validate({
   				errorClass : "errorWarning",
   				errorPlacement : function(){
   					return false; // suppress error message text
   				}
   			});

            // elements targeted in Mortgage Pre-Qualification Form
            var mpqfElems = {
            	form1003 		: $('.form1003'),
            	step1Content 	: $('.step-1-container'),
            	step2Content	: $('.step-2-container'),
            	step3Content	: $('.step-3-container'),
            	step4Content	: $('.step-4-container'),
            	step5Content	: $('.step-5-container'),
            	step6Content	: $('.step-6-container'),
            	mpqfDropdowns	: $('.cs-select.cs-skin-border'),
            	mpqfSteps 		: $('.mpqf-steps'),
            	mpqfSet1		: $('.mpqf-set1'),
            	mpqfSet2		: $('.mpqf-set2'),
            	mpqfStep1 		: $('.mpqf-step-1'),
            	mpqfArrows		: $('.mpqf-arrow-right'),
            	arrow1	 		: $('.arrow1'),
            	mpqfStep2 		: $('.mpqf-step-2'),
            	arrow2	 		: $('.arrow2'),
            	mpqfStep3 		: $('.mpqf-step-3'),
            	mpqfStep4		: $('.mpqf-step-4'),
            	arrow4			: $('.arrow4'),
            	mpqfStep5 		: $('.mpqf-step-5'),
            	arrow5			: $('.arrow5'),
            	mpqfStep6 		: $('.mpqf-step-6'),
            	nextButton 		: $('.mpqf-next-button'),
            	backButton 		: $('.mpqf-back-button'),
            	submitButton 	: $('.mpqf-button.noselect.submit'),
            	secureLink		: $('.mpqf-secure'),
            	resCheckBox		: $('.checkbox-current-address input'),
            	resAddress		: $('.residence-address-container'),
            	acceptTerms		: $('.mpqf-checkbox-paragraphs .accepts-terms'),
            	prePopAdd		: $('.pre-pop-borrower-address'),
            	coborrowRadio	: $('input[name="hascoborrower_3840"]'),
            	coborrowRadioVal: $('input[name="hascoborrower_3840"]:checked').val(),
            	propertyStreet	: $('input[name="propertystreetaddress_11"]'),
            	propertyCity	: $('input[name="propertycity_12"]'),
            	propertyState	: $('select[name="propertystate_14"]'),
            	propertyZip		: $('input[name="propertyzipcode_15"]'),
            	resStreet		: $('input[name="residencestreetaddress_FR0104"]'),
            	resCity			: $('input[name="residencecity_FR0106"]'),
            	resState		: $('input[name="residencestate_FR0107"]'),
            	resZip			: $('input[name="residencezipcode_FR0108"]'),
            	coborrowerStreet: $('input[name="coborrowerpropertystreetaddress_FR0204"]'),
            	coborrowerCity	: $('input[name="coborrowerpropertycity_FR0206"]'),
            	coborrowerState	: $('input[name="coborrowerpropertystate_FR0207"]'),
            	coborrowerZip	: $('input[name="coborrowerpropertyzipcode_FR0208"]'),
            	firstname 		: $('input[name="firstname_36"]'),
            	lastname 		: $('input[name="lastname_37"]'),
            	email			: $('input[name="email_1240"]'),
            	taxno			: $('input[name="taxnumber_65"]'),
            	homephone		: $('input[name="homephone_66"]'),
               citizenship    : $('select[name="citizenship_965"]'),
               military       : $('select[name="military"]'),
               estCreditRating       : $('select[name="est_credit_rating"]'),
            	prefLoanOfficer :$('.preferred-loan-officer'),
            	dateofbirth		: $('input[name="dob_1402"]'),
            	moneyFormat		: $('.moneyFormat'),
               purposeOfLoan  : $('select[name="purposeofloan_19"]'),
               propertyUsage  : $('select[name="propertyusage_1811"]'),
               loanAmount     : $('input[name="loanamount_1109"]'),
               baseIncome     : $('input[name="baseincome_101"]'),
            	step3reached	: false,
            	lnOfficerScroll : false
            };


            // set button config for inactive state
            var inactiveButtonParams = { opacity:'.3', cursor:'auto', border:'1px solid gray' };

            // set button config for active state
            var activeButtonParams = { opacity:'1', cursor:'pointer', border:'1px solid #649bd7' };

            function scrollUp() {
               $('html, body').animate({ scrollTop: mpqfElems.secureLink.offset().top -170 }, 100);
            }

            // turn submit button into next button when needed
            function makeNextButton(){
            	if(mpqfElems.nextButton.hasClass('submit')){
            		mpqfElems.nextButton.text('Next').css('opacity','1');
            		mpqfElems.nextButton.addClass('mpqf-next-button').removeClass('submit');
            	}
            	return;
            }

            // turn next button into submit button when needed
            function makeSubmitButton(){
            	mpqfElems.nextButton.removeClass('mpqf-next-button').addClass('submit');
            	mpqfElems.nextButton.text('Submit');
            	mpqfElems.nextButton.css(inactiveButtonParams);
            }
            function deactivateButton(){
            	mpqfElems.nextButton.css(inactiveButtonParams);
            	mpqfElems.nextButton.removeClass('active');
            }
            function activateButton(){
            	mpqfElems.nextButton.css(activeButtonParams);
            	mpqfElems.nextButton.addClass('active');
            }
            function setButtonBasedOnTermAcceptance(){
            	mpqfElems.coborrowRadioVal = $('input[name="hascoborrower_3840"]:checked').val();
            	if(typeof mpqfElems.coborrowRadioVal === 'undefined') {
                  mpqfElems.coborrowRadioVal = 'Y';
               }

            	if(mpqfElems.acceptTerms.is(':checked') && mpqfElems.coborrowRadioVal === 'N' ) {
            		makeNextButton();
            		activateButton();
            	} else if(mpqfElems.acceptTerms.is(':checked') && mpqfElems.coborrowRadioVal === 'Y' ){
            		makeSubmitButton();
            		activateButton();
            	} else if(mpqfElems.acceptTerms.not(':checked') && mpqfElems.coborrowRadioVal === 'N' ) {
            		makeNextButton();
            		deactivateButton();
            	} else if(mpqfElems.acceptTerms.not(':checked') && mpqfElems.coborrowRadioVal === 'Y' ) {
            		makeSubmitButton();
            		deactivateButton();
            	}
            	if (mpqfElems.acceptTerms.is(':checked')) {
                  activateButton();
               } else {
                  deactivateButton();
               }
            }

            function setButtonBasedOnCoborrower(){
            	mpqfElems.coborrowRadioVal = $('input[name="hascoborrower_3840"]:checked').val();
            	if( mpqfElems.coborrowRadioVal === 'N' && mpqfElems.acceptTerms.is(':checked') ){
            		makeNextButton();
            		activateButton();
            	} else if( mpqfElems.coborrowRadioVal === 'N' && mpqfElems.acceptTerms.not(':checked') ){
            		makeNextButton();
            		deactivateButton();
            	} else if( mpqfElems.coborrowRadioVal === 'Y' && mpqfElems.acceptTerms.is(':checked') ){
            		makeSubmitButton();
            		activateButton();
            	} else if( mpqfElems.coborrowRadioVal === 'Y' && mpqfElems.acceptTerms.not(':checked') ){
            		makeSubmitButton();
            		deactivateButton();
            	}
            }
            function configStepElements(step){
            	var formRemoveClasses = {
                  one:'mpqf-step-2 mpqf-step-3',
                  two:'mpqf-step-1 mpqf-step-3',
                  three:'mpqf-step-1 mpqf-step-2 mpqf-step-4 mpqf-step-5 mpqf-step-6',
                  four:'mpqf-step-1 mpqf-step-2 mpqf-step-3 mpqf-step-5 mpqf-step-6',
                  five:'mpqf-step-4 mpqf-step-6',
                  six:'mpqf-step-4 mpqf-step-5'
               };
               var removeClasses, AddClass, hideStep1,
                  hideStep2, showStep, brdCrmbNum, arrowNum,
                  showBackButton, checkButtonStates;

            	switch(step){
            		case 1 :
                     removeClasses = formRemoveClasses.one;
                     AddClass='mpqf-step-1';
                     hideStep1 = mpqfElems.step2Content;
                     hideStep2 = mpqfElems.step3Content;
                     showStep = mpqfElems.step1Content;
                     brdCrmbNum = mpqfElems.mpqfStep1;
                     arrowNum = mpqfElems.arrow1;
                     showBackButton = false;
                     checkButtonStates = false;
                     break;
            		case 2 :
                     removeClasses = formRemoveClasses.two;
                     AddClass='mpqf-step-2';
                     hideStep1 = mpqfElems.step1Content;
                     hideStep2 = mpqfElems.step3Content;
                     showStep = mpqfElems.step2Content;
                     brdCrmbNum = mpqfElems.mpqfStep2;
                     arrowNum = mpqfElems.arrow2;
                     showBackButton = true;
                     checkButtonStates = false;
                     break;
            		case 3 :
                     removeClasses = formRemoveClasses.three;
                     AddClass='mpqf-step-3';
                     hideStep1 = mpqfElems.step1Content;
                     hideStep2 = mpqfElems.step2Content;
                     showStep = mpqfElems.step3Content;
                     brdCrmbNum = mpqfElems.mpqfStep3;
                     arrowNum = false;
                     showBackButton = true;
                     checkButtonStates = true;  break;
            		case 4 :
                     removeClasses = formRemoveClasses.four;
                     AddClass='mpqf-step-4';
                     hideStep1 = mpqfElems.step5Content;
                     hideStep2 = mpqfElems.step6Content;
                     showStep = mpqfElems.step4Content;
                     brdCrmbNum = mpqfElems.mpqfStep4;
                     arrowNum = mpqfElems.arrow4;
                     showBackButton = true;
                     checkButtonStates = false; break;
            		case 5 :
                     removeClasses = formRemoveClasses.five;
                     AddClass='mpqf-step-5';
                     hideStep1 = mpqfElems.step4Content;
                     hideStep2 = mpqfElems.step6Content;
                     showStep = mpqfElems.step5Content;
                     brdCrmbNum = mpqfElems.mpqfStep5;
                     arrowNum = mpqfElems.arrow5;
                     showBackButton = true;
                     checkButtonStates = false; break;
            		case 6 :
                     removeClasses = formRemoveClasses.six;
                     AddClass='mpqf-step-6';
                     hideStep1 = mpqfElems.step4Content;
                     hideStep2 = mpqfElems.step5Content;
                     showStep = mpqfElems.step6Content;
                     brdCrmbNum = mpqfElems.mpqfStep6;
                     arrowNum = false;
                     showBackButton = true;
                     checkButtonStates = true;  break;
            	}
            	mpqfElems.form1003.removeClass(removeClasses).addClass(AddClass);
            	hideStep1.hide();
            	hideStep2.hide();
            	if(step === 4) { mpqfElems.step3Content.hide(); }
            	if(step === 3) { mpqfElems.step4Content.hide(); }
            	showStep.css('display','inline-block');
            	mpqfElems.mpqfArrows.removeClass('blue').addClass('gray');
            	mpqfElems.mpqfSteps.removeClass('blue').addClass('gray');
            	brdCrmbNum.removeClass('gray').addClass('blue');
            	if(arrowNum){ arrowNum.removeClass('gray').addClass('blue'); }
            	if (showBackButton === false) {
                  mpqfElems.backButton.hide();
               } else {
                  mpqfElems.backButton.show();
               }
            	if(checkButtonStates === false){ makeNextButton(); activateButton(); }
            	else { setButtonBasedOnTermAcceptance(); setButtonBasedOnCoborrower(); }
            	if(step === 2){
            		if (mpqfElems.resCheckBox.is(':checked')){
            			mpqfElems.resAddress.css('display','inline-block');
            		} else {
            			mpqfElems.resAddress.hide();
            		}
            	} else {
            		mpqfElems.resAddress.hide();
            	}
            	if(step === 6){ makeSubmitButton(); activateButton(); }
            	if(step === 2 && mpqfElems.lnOfficerScroll === true){
            		$('html, body').animate({ scrollTop: $('body').offset().top + 800 }, 200);
            	} else {
            		scrollUp();
            	}
            }

            function validateStep(vs, gotoStep) {

            	var fstNmValid = mpqfElems.firstname.valid();
            	var lstNmValid = mpqfElems.lastname.valid();
            	var emlAdValid = mpqfElems.email.valid();
               var dobValid = mpqfElems.dateofbirth.valid();
               var citizenValid = mpqfElems.citizenship.valid();
               var militaryValid = mpqfElems.military.valid();
            	var hmphnValid = mpqfElems.homephone.valid();
               var propStreetValid = mpqfElems.propertyStreet.valid();
               var propCityValid = mpqfElems.propertyCity.valid();
               var propStateValid = mpqfElems.propertyState.valid();
               var propZipValid = mpqfElems.propertyZip.valid();
               var estCreditValid = mpqfElems.estCreditRating.valid();
               var purposeOfLoanValid = mpqfElems.purposeOfLoan.valid();
               var propertyUsageValid = mpqfElems.propertyUsage.valid();
               var loanAmountValid = mpqfElems.loanAmount.valid();
               var baseIncomeValid = mpqfElems.baseIncome.valid();

            	if(vs === 1) {
            		// perform step one validation before continuing
            		if(fstNmValid && lstNmValid && emlAdValid && hmphnValid && propStreetValid && propCityValid && propStateValid && propZipValid){
            			// if all fields are valid continue to goto step
            			configStepElements(gotoStep);

                     createIntegrations();
            		} else {
            			scrollUp();
            		}
            	} else if (vs === 2) {
            		// first validate step one
            		if(fstNmValid && lstNmValid && emlAdValid && hmphnValid && propStreetValid && propCityValid && propStateValid && propZipValid){
            			// Currently, step two has a single field that needs to be validated, that being "preferred loan officer".
            			// The following code checks for validity and scrolls to the field if it has not been addressed.
            			// var prefLoanOfficer = $('.preferred-loan-officer');
            			if( !dobValid ) {
            				mpqfElems.lnOfficerScroll = true;
            				configStepElements(2);
            			} else {
            				mpqfElems.lnOfficerScroll = false;
            				configStepElements(gotoStep);
            			}
            		} else {
            			// step one is not valid... show step one and scroll up
            			configStepElements(1);
            			scrollUp();
            		}

            	}
            }

            function createIntegrations() {
               var arrayOfData = [], dataToSend;
               var params = {};

               $('.step-1-container').each(function(index, elem){
                  $(elem).find('input:not([type="checkbox"]), select').each(function(index, secElem){
                     dataToSend = {};
                     dataToSend['name'] = $(secElem).attr('name');
                     dataToSend['value'] = $(secElem).val();
                     arrayOfData.push(dataToSend);
                  });
               });

               /* Add the 1003 CID to the data to send. */
               var cidData = {
                  'name': 'cid',
                  'value': $('#cid').val()
               };
               params["cid"] = cidData.value;

               $.each(arrayOfData, function(key,val){
                 params[val.name.split("_")[0]] = val.value;
               });

               $.ajax({
                  type: "POST",
                  dataType: "jsonp",
                  url: "/wp-content/themes/nlc_custom/lead-post.php?" + $.param(params)
               }).done( function(data, textStatus, jqXHR){
                  console.log(data);
                  if ( data['msg'] == "success" ) {
                    $('#velocify_ID').val(data.redirect);
                  }
               }).fail( function(jqXHR, textStatus, errorThrown){
               });
            }

            // next button click handler
            mpqfElems.nextButton.on('click', function(e){
            	e.preventDefault();
            	if($(this).hasClass('active') && $(this).hasClass('submit')){
            		//alert('They have accepted terms and don\'t have a co-borrower, so validate the form and proceed as appropriate.');
            		// Submit form
                  $.ajax({
                     type: "POST",
                     dataType: "json",
                     url: "/wp-content/themes/nlc_custom/lib/secure-post.php",
                     data: {
                        'formData' : $('#form1003').serializeArray()
                     }
                  }).done( function(data, textStatus, jqXHR){
                     console.log(data);
                  }).fail( function(jqXHR, textStatus, errorThrown){
                  });

                  // Immediately show the thank you step
                  $('.block-form-header-container').fadeOut(200, function(){
                     $('.mpqf-form-container .block-form-section button, .mpqf-disclaimer, .mpqf-steps, .mpqf-required').hide();
                     $('.mpqf-steps-container').css({
                        'background': '#649bd7',
                        'height': '40px'
                     });
                     scrollUp();
                     $('.thank-you').fadeIn(200);
                  });

            		return false;
            	} else if($(this).hasClass('submit')){
            		alert('please accept the terms of the privacy policy.');
            		// do not advance until conditions are met.
            		//alert('user needs to accept privacy policy terms so do nothing or let them know etc.');
            		console.log('stopped in conditional');
            		return false;
            	}

            	if(mpqfElems.form1003.hasClass('mpqf-step-1')){
            		// perform step one validation before continuing
            		validateStep(1,2);
            	} else if(mpqfElems.form1003.hasClass('mpqf-step-2')){
            		validateStep(2,3);
            	} else if(mpqfElems.form1003.hasClass('mpqf-step-3')){
            		validateStep(2,4);
            		mpqfElems.mpqfSet1.hide();
            		mpqfElems.mpqfSet2.css('display','inline-block');
            		configStepElements(4);
            	} else if(mpqfElems.form1003.hasClass('mpqf-step-4')){
            		configStepElements(5);
            	} else if(mpqfElems.form1003.hasClass('mpqf-step-5')){
            		configStepElements(6);
            	}
            });

            // back button click handler
            mpqfElems.backButton.click(function(e){
            	e.preventDefault();
            	if(mpqfElems.form1003.hasClass('mpqf-step-2')){
            		configStepElements(1);
            	} else if (mpqfElems.form1003.hasClass('mpqf-step-3')){
            		configStepElements(2);
            	} else if (mpqfElems.form1003.hasClass('mpqf-step-4')){
            		mpqfElems.mpqfSet1.css('display','inline-block');
            		mpqfElems.mpqfSet2.hide();
            		configStepElements(3);
            	} else if (mpqfElems.form1003.hasClass('mpqf-step-5')){
            		configStepElements(4);
            	} else if (mpqfElems.form1003.hasClass('mpqf-step-6')){
            		configStepElements(5);
            	}
            });

            // submit button click handler
            mpqfElems.submitButton.click(function(e){
            	console.log('click detected');
            	e.preventDefault();
            	alert('stop!');
            });

            // change handler for residential address checkbox
            mpqfElems.resCheckBox.change(function(){
            	if ($(this).is(':checked')) {
                  mpqfElems.resAddress.slideDown(280);
               } else {
                  mpqfElems.resAddress.slideUp(280);
               }
            });

            // change handler for Co Borrower's Address Pre-population button
            mpqfElems.prePopAdd.change(function(){
               // Change to grab select dropdown value from the state!!!
               var resStateValue = $('select[name="residencestate_FR0107"]').val();
               var propStateValue = $('select[name="propertystate_14"]').val();
               var coBorrowerStateField = $('select[name="coborrowerpropertystate_FR0207"]');
            	if($(this).is(':checked')){
            		if(mpqfElems.resCheckBox.is(':checked')){
            			mpqfElems.coborrowerStreet.val(mpqfElems.resStreet.val());
            			mpqfElems.coborrowerCity.val(mpqfElems.resCity.val());
            			coBorrowerStateField.val(resStateValue);
            			mpqfElems.coborrowerZip.val(mpqfElems.resZip.val());
            		} else {
            			var propertyStreetValue = mpqfElems.propertyStreet.val().replace(/ /g,'');
            			if(propertyStreetValue){
            				mpqfElems.coborrowerStreet.val(mpqfElems.propertyStreet.val());
            				mpqfElems.coborrowerCity.val(mpqfElems.propertyCity.val());
            				coBorrowerStateField.val(propStateValue);
            				mpqfElems.coborrowerZip.val(mpqfElems.propertyZip.val());
            			} else {
            				alert('No borrower address information is available. Please check step 2.');
            				$(this).attr('checked', false);
            			}

            		}
            	} else {
            		mpqfElems.coborrowerStreet.val('');
            		mpqfElems.coborrowerCity.val('');
            		coBorrowerStateField.val('');
            		mpqfElems.coborrowerZip.val('');
            	}
            });

            // change handler for accept terms checkbox
            mpqfElems.acceptTerms.change(function(){
            	setButtonBasedOnTermAcceptance();
            });

            // change handler for Co-Borrower radio buttons
            mpqfElems.coborrowRadio.change(function(){
            	setButtonBasedOnCoborrower();
            });

            // submit button click handler
            $('.mpqf-button.submit').click(function(e){
            	e.preventDefault();
            	alert('hello world.');
            });

            // click handlers for breadcrumb arrows at top of form
            $('.mpqf-steps').click(function(){
            	if($(this).hasClass('mpqf-step-1')){
            		 configStepElements(1);
            	} else if ($(this).hasClass('mpqf-step-2')) {
            		validateStep(1, 2);
            	} else if ($(this).hasClass('mpqf-step-3')) {
            		validateStep(2,3);
            	} else if ($(this).hasClass('mpqf-step-4')) {
            		configStepElements(4);
            	} else if ($(this).hasClass('mpqf-step-5')) {
            		configStepElements(5);
            	} else if ($(this).hasClass('mpqf-step-6')) {
            		configStepElements(6);
            	}
            });
         },
         finalize: function() {

         }
      },
      // Pre-Qualification Page
      'pre_qualification_form': {
         init: function() {

         },
         finalize: function() {

         }
      },
      'meet_the_team': {
         init: function() {

         },
         finalize: function() {

         }
      },
      // About us page, note the change from about-us to about_us.
      'about_nlc': {
         init: function() {
            // JavaScript to be fired on the about us page
            $('#branch_locator').on('change', function(event){
               var $this = $(this);
               $('.branch-container').hide();
               var selectedValue = $this.find('option:selected').val();

               $('div[data-state="' + selectedValue + '"]').fadeIn(200);
            }).trigger('change');
         }
      },
      'rates': {
         init: function() {
            // Rates page expandable container
            if ( $('.participating-branches').length > 0 ) {
               $('.participating-branches').on('click', function(event){
                  event.preventDefault();
                  var $this = $(this);
                  var href = $this.attr('href').slice(1);
                  if ( $('.' + href).css('display') === "none" ) {
                     $('.' + href).fadeIn(200);
                  } else {
                     $('.' + href).hide();
                  }
               });
            }
         }
      },
      'partner': {
         init: function() {
            /* Nations Branch Partner Accordion */
            $('.accordion-toggle').on('click', function(event){
               event.preventDefault();

               var $accordion = $(this);
               var $accordionContent = $accordion.next('.accordion-content');
               var $accordionToggleIcon = $accordion.children('.toggle-icon');

               // Toggle accordion link 'open' class
               $accordion.toggleClass('open');

               // Toggle accordion content
               $accordionContent.slideToggle(300);

               // Change the caret icon
               if ( $accordion.hasClass('open') ) {
                  $accordionToggleIcon.html('<i class="fa fa-angle-up"></i>');
               } else {
                  $accordionToggleIcon.html('<i class="fa fa-angle-down"></i>');
               }
            });
         }
      },
      // Unsubscribe page
      'unsubscribe': {
         init: function() {
            $( '.unsubscribe-button' ).on('click', function(event){
               event.preventDefault();
               $( this ).html('<i class="fa fa-spinner fa-spin"></i>');

               $.ajax({
                  type: "POST",
                  dataType: "json",
                  url: "/wp-content/themes/nlc_custom/lib/updateUnsubscribeEloqua.php",
                  data: {
                     'email' : $( '#unsubscribe_field' ).val()
                  }
               }).done( function(data, textStatus, jqXHR) {
                  console.log(data);
                  $('.unsubscribe-container').fadeOut(200, function(){
                     $('.hlp').fadeIn(200);
                  });
               }).fail( function(jqXHR, textStatus, errorThrown) {

                  console.log('jqXHR: ' + JSON.stringify(jqXHR));
                  console.log('textStatus: ' + textStatus);
                  console.log('errorThrown: ' + errorThrown);

               });
            });
         }
      }
   };

   // The routing fires all common scripts, followed by the page specific scripts.
   // Add additional events for more control over timing e.g. a finalize event
   var UTIL = {
      fire: function(func, funcname, args) {
         var fire;
         var namespace = Sage;
         funcname = (funcname === undefined) ? 'init' : funcname;
         fire = func !== '';
         fire = fire && namespace[func];
         fire = fire && typeof namespace[func][funcname] === 'function';

         if (fire) {
            namespace[func][funcname](args);
         }
      },
      loadEvents: function() {
         // Fire common init JS
         UTIL.fire('common');

         // Fire page-specific init JS, and then finalize JS
         $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
            UTIL.fire(classnm);
            UTIL.fire(classnm, 'finalize');
         });

         // Fire common finalize JS
         UTIL.fire('common', 'finalize');
      }
   };

   // Load Events
   $(document).ready(UTIL.loadEvents);

   $('.openDownload').click(function(e){
      e.preventDefault();
      console.log('open download');
      $('.download-overlay').addClass('open');
      $("html, body").animate({ scrollTop: "0px" });
   });

   $('.download-overlay .close').click(function(e){
      e.preventDefault();
      console.log('cose download');
      $('.download-overlay').removeClass('open');
   });

   $('.download-overlay .close-button').click(function(e){
      e.preventDefault();
      console.log('cose download');
      $('.download-overlay').removeClass('open');
   });

   $('#download-form').submit(function(event){
      //event.preventDefault();
   });
   var globalFormSuccess = 'false';

   $('.submit-download-form').click(function(){
      e.preventDefault();
      $('.download-form').submit();
   });

   $('#download_infographic_form a.submit-download').click(function(){
      $('#download_infographic_form').submit();
   });

   $('#download_infographic_form').submit(function(){
      //event.preventDefault();
      $('.download-section .content-container #download_infographic_form input[type="submit"]').prop('disabled', true);

      $.ajax({
         type: "POST",
         crossDomain: true,
         dataType: "jsonp",
         url: $(this).attr('action'),
         data: $(this).serialize()
      }).success( function(){
         $('.download-section .content-container').hide();
         $('.download-section .thank-you-message').show();
      }).fail(function(){
         $('.download-section .content-container #download_infographic_form input[type="submit"]').prop('disabled', false);
      });

   });

   $('a.rate-lightbox').click(function(event){
      event.preventDefault();
      $('div.rate-lightbox').addClass('open');
      $('div.rate-lightbox-overlay').addClass('open');
   });

   $('div.rate-lightbox .close').click(function(){
      $('div.rate-lightbox').removeClass('open');
      $('div.rate-lightbox-overlay').removeClass('open');
   });

   $('div.rate-lightbox-overlay').click(function(){
      $('div.rate-lightbox').removeClass('open');
      $('div.rate-lightbox-overlay').removeClass('open');
   });

//    $('div.benefits-wrapper').mouseenter(function() {
//         $('.benefits-base').addClass("compensation-hover");
//         $('benefits-hover').removeClass("compensation-hover");
//    });

//     $('div.benefits-wrapper').mouseleave(function() {
//         $('.benefits-base').removeClass("compensation-hover");
//         $('benefits-hover').addClass("compensation-hover");
//    });
   
   $('.career-section-link').click(function(e){
      console.log('THIS WAS CLICKED');
      e.preventDefault();
         
         var careerTop = $('#careers-section').offset().top - 200;
         console.log($('#careers-section').offset().top);
         console.log(careerTop);

       $('html, body').animate({
           scrollTop: careerTop
       }, 500);

   });
   
   $('#career-scroll').click(function(e){
      console.log('THIS float WAS CLICKED');
      e.preventDefault();
         
         var careerTop = $('#careers-section').offset().top - 200;
         console.log($('#careers-section').offset().top);
         console.log(careerTop);

       $('html, body').animate({
           scrollTop: careerTop
       }, 500);

   });




})(jQuery); // Fully reference jQuery after this point.
