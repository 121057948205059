(function($){

   document.body.style.overflow = "hidden";
   var vpWidth = $(window).outerWidth();
   document.body.style.overflow = "";

   var widthNumeric = jQuery.isNumeric(vpWidth);
   if (!widthNumeric) {
      parseInt(vpWidth);
   }

   if (vpWidth < 768) {
      var mobileWidth = true;
   } else {
      var mobileWidth = false;
   }

   $(document).on('click', function(){
      $('.dropdown-menu').hide();

      if (mobileWidth && !$('.main-nav').hasClass('hidden')) {
         menuButton.trigger('click');
      }
   });

   $('.dropdown > a').on('click', function(evt){
      evt.preventDefault();

      if (mobileWidth) {
         $(this).siblings('.fa').trigger('click');
      }
   });

   //SET SCROLLING FOR DROPDOWN MENU
   // Get height of viewport
   function setDropdownHeight() {
      document.body.style.overflow = "hidden";
      var viewportHeight = $(window).height();
      var navbarHeight = $('#page_nav').height();
      document.body.style.overflow = "";

      var checkViewportNumeric = jQuery.isNumeric(viewportHeight);
      var checkNavbarNumeric = jQuery.isNumeric(navbarHeight);
      if (!checkViewportNumeric) {
         parseInt(viewportHeight);
      } else if (!checkNavbarNumeric) {
         parseInt(navbarHeight);
      }

      $('#menu-primary-navigation').css('max-height', 'none');

      var getDropdownHeight = $('#menu-primary-navigation').height();
      var setDropdownHeight = viewportHeight - navbarHeight;

      $('#menu-primary-navigation').css({
         'max-height': setDropdownHeight,
         'overflow-y': 'auto'
      });

      // prevent scrolling on body
      if ($('body').css('overflow-y') !== 'hidden') {
         $('body').css('overflow-y', 'hidden');
      }
   }

   // Caret Set up for mobile nav dropdowns
   $('.main-nav li.dropdown').append('<span class="fa fa-caret-down" />')
   $('.main-nav .caret').remove();

   // remove active class
   $('.main-nav .active').removeClass('active');

   var menuButton = $('.button-mobile-menu');

   menuButton.on('click', function(evt){
      evt.stopPropagation();
      // toggle menu bars and closing x icons
      $(this).children('.fa').toggleClass('fa-bars fa-times');
      // place handler on main navigation
      var mainNav = $(this).parent().siblings('.main-nav')
      // place handler on navs menu
      var mainNavMenu = mainNav.children();

      // check to see if navigation is not showing
      if (mainNav.hasClass('hidden')) {
         // show main nav
         mainNav.removeClass('hidden');
         // set dropdown height
         setDropdownHeight();
         // switch button's borders
         $(this).css('border-left', '0px');
         $(this).css('border-right', '1px solid #939393');
         // get width of menu button
         var menuButtonWidth = $(this).outerWidth();
         // get width of navbar-header
         var navHeaderWidth = $(this).parent().width();
         // get difference of navbar-header and menu button to know how much to slide left
         var slideAmount = navHeaderWidth-menuButtonWidth;
         // make menu width same as slide amount
         mainNavMenu.width(slideAmount);
         // slide button left
         $(this).animate({marginRight: '+=' + slideAmount + 'px'});
         // slide menu left same amount
         mainNavMenu.animate({marginLeft: '-=' + slideAmount + 'px'});
      } else {
         // switch button's borders
         $(this).css('border-right', '0px');
         $(this).css('border-left', '1px solid #939393');
         // get width of menu button
         var menuButtonWidth = $(this).outerWidth();
         // get width of navbar-header
         var navHeaderWidth = $(this).parent().width();
         // get difference of navbar-header and menu button to know how much to slide left
         var slideAmount = navHeaderWidth-menuButtonWidth;
         // slide button back right
         $(this).animate({marginRight: '0px'});
         // Take open item classes away
         $('.open-menu-item-secondary').removeClass('open-menu-item-secondary');
         $('.open-menu-item-tertiary').removeClass('open-menu-item-tertiary')
         $('.dropdown .fa-caret-up').toggleClass('fa-caret-up fa-caret-down');
         // slide menu right same amount
         mainNavMenu.animate({
            marginLeft: '0px'},
            function() {
               mainNav.addClass('hidden');
            }
         );
         // allow body scrolling again
         $('body').css('overflow-y', 'visible');
      }
   });

   // sub navigtions
   // place hande on top menus items with sub menus
   var topLevelDropdowns = $('.main-nav .header-menu > li.dropdown');
   var secondLevelDropdowns = topLevelDropdowns.find('li.dropdown');

   topLevelDropdowns.on('click', function(evt){

      evt.stopPropagation();

      if(mobileWidth) {

         if ($(evt.target).is('.fa')) {
            var indicator = $(evt.target);

            if (indicator.hasClass('fa-caret-down')) {
               var otherOpenMenus = indicator.parent().siblings('.open-menu-item-secondary');
               otherOpenMenus.children('.fa-caret-up').trigger('click');
               otherOpenMenus.find('.open-menu-item-tertiary').children('.fa-caret-up').trigger('click');
               indicator.siblings('.dropdown-menu').slideDown();
               indicator.toggleClass('fa-caret-down fa-caret-up');
               indicator.parent().addClass('open-menu-item-secondary');
            } else if (indicator.hasClass('fa-caret-up')) {
               indicator.siblings('.dropdown-menu').slideUp();
               indicator.toggleClass('fa-caret-down fa-caret-up');
               indicator.parent().removeClass('open-menu-item-secondary');
            }
         }
      } else {

         if ($(evt.target).is('a.disabled')) {
            evt.preventDefault();
         }

         var secondDropdownMenuVisible = $(this).siblings('.dropdown').find('ul:visible');
         var secondDropdownMenu = $(this).children('ul');
         var leftDropdownOffset = $(this).offset().left;
         secondDropdownMenuVisible.toggle();
         secondDropdownMenu.toggle();
         secondDropdownMenu.find('.fa-caret-down').toggleClass('fa-caret-down fa-caret-right');

         if (leftDropdownOffset < (secondDropdownMenu.outerWidth() - $(this).outerWidth())) {
            secondDropdownMenu.css({'right': 'auto', 'left': '0'});
         }
      }

   });

   secondLevelDropdowns.on('click', function(evt) {
      evt.stopPropagation();

      if(mobileWidth) {

         if ($(evt.target).is('.fa')) {
            var indicator = $(evt.target);

            if (indicator.hasClass('fa-caret-down')) {
               var otherOpenMenus = indicator.parent().siblings('.open-menu-item-tertiary');
               otherOpenMenus.children('.fa-caret-up').trigger('click');
               indicator.siblings('.dropdown-menu').slideDown();
               indicator.toggleClass('fa-caret-down fa-caret-up');
               indicator.parent().addClass('open-menu-item-tertiary');
            } else if (indicator.hasClass('fa-caret-up')) {
               indicator.siblings('.dropdown-menu').slideUp();
               indicator.toggleClass('fa-caret-down fa-caret-up');
               indicator.parent().removeClass('open-menu-item-tertiary');
            }
         }

      } else {

         if ($(evt.target).is('a.disabled')) {
            evt.preventDefault();
         }

         var tertiaryDropdownMenu = $(this).children('ul');
         var tertiaryDropdownMenuVisible = $(this).siblings('.dropdown').find('ul:visible');
         var rightDropdownOffset = $(window).width() - ($(this).offset().left + $(this).outerWidth());
         tertiaryDropdownMenuVisible.toggle();
         tertiaryDropdownMenu.toggle();
         var largestItemWidth = 0

         tertiaryDropdownMenu.children().each(function(){
            var linkWidth = $(this).children('a').outerWidth();

            if (linkWidth > largestItemWidth) {
               largestItemWidth = linkWidth+30;
            }
         });

         tertiaryDropdownMenu.outerWidth(largestItemWidth);

         if (rightDropdownOffset < tertiaryDropdownMenu.outerWidth()) {
            tertiaryDropdownMenu.css({'left': 'auto', 'right': '100%'});
         }
      }

   })

   // FUNCTIONALITY ON WINDOW RESIZING
   var previousSize = vpWidth;
   $(window).resize(function() {
      document.body.style.overflow = "hidden";
      var viewportWidth = $(window).outerWidth();
      document.body.style.overflow = "";

      var checkViewportWidthNumeric = jQuery.isNumeric(viewportWidth);
      if (!checkViewportWidthNumeric) {
         parseInt(viewportWidth);
      }

      if (viewportWidth < 768) {
         mobileWidth = true;

         if (previousSize >= 768) {
            $('#page_nav .main-nav *').attr('style', '');
         }

         $('#menu-primary-navigation > li.dropdown.open-menu-item-secondary').removeClass('open-menu-item-secondary');
         $('#menu-primary-navigation > li.dropdown .fa-caret-up').toggleClass('fa-caret-down fa-caret-up');

         if (!$('#page_nav .main-nav').hasClass('hidden')) {
            var mainNavMenu = $('#page_nav .main-nav').children();
            // get width of navbar-headers
            var navHeaderWidth = $('#page_nav .navbar-header').width();
            // get width of menu button
            var menuButtonWidth = menuButton.outerWidth();
            // get difference of navbar-header and menu button to know how much to slide left
            var slideAmount = (navHeaderWidth-menuButtonWidth);
            // make menu width same as slide amount
            mainNavMenu.width(slideAmount);
            // slide button left
            $('.button-mobile-menu').css('margin-right', slideAmount + 'px');
            // slide menu left same amount
            mainNavMenu.css('marginLeft', '-' + slideAmount + 'px');
            // set dropdown height
            setDropdownHeight();
         }

         previousSize = viewportWidth;

      } else {
         mobileWidth = false;

         if (previousSize < 768) {
            $('#page_nav .main-nav *').attr('style', '');
         }

         $('#menu-primary-navigation > li.dropdown > ul > li.dropdown > .fa-caret-right').toggleClass('fa-caret-down fa-caret-right');

         if (!$('#page_nav .main-nav').hasClass('hidden')) {
            menuButton.trigger('click');
         }

         previousSize = viewportWidth;
      }
   });
})(jQuery);
