/**
 * Foreach loop polyfill
 * https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Array/forEach#Compatibility
 */
if ( !Array.prototype.forEach ) {
	Array.prototype.forEach = function( callback, thisArg ) {
		var T, k;

		if ( this == null ) {
			throw new TypeError( 'This is null or not defined' );
		}

		// 1. Let O be the result of calling ToObject passing the |this| value as the argument
		var O = Object(this);

		// 2. Let lenValue be the result of calling the Get internal method of O with the argument "length".
		// 3. Let len be ToUint32(lenValue).
		var len = O.length >>> 0;

		// 4. If IsCallable(callback) is false, throw a TypeError exception
		// See: http://es5.github.com/#x9.11
		if ( typeof callback !== "function" ) {
			throw new TypeError( callback + ' is not a function.' );
		}

		// 5. If thisArg was supplied, let T be thisArg; else let T be undefined.
		if ( arguments.length > 1 ) {
			T = thisArg;
		}

		// 6. Let k be 0
		k = 0;

		// 7. Repeat, while k < len
		while ( k < len ) {
			var kValue;

			// a. Let Pk be ToString(k)
			//		This is implicit for LHS operands of the in operator
			// b. Let kPresent be the result of calling the HasProperty internal method of O with argument Pk
			//		This step can be combined with c
			// c. If kPresent is true, then
			if ( k in O ) {
				// i. Let kValue be the result of calling the Get internal method of O with argument Pk
				kValue = O[k];

				// ii. Call the Call internal method of callback with T as the 'this' value and
				// argument list containing kValue, k, and O
				callback.call(T, kValue, k, O);
			}
			// d. Increase k by 1.
			k++;
		}
		// 8. Return undefined
	};
}

/**
 * indexOf polyfill
 * https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Array/indexOf
 */
if ( !Array.prototype.indexOf ) {
	Array.prototype.indexOf = function( searchElement, fromIndex ) {
		var k;

		if ( this == null ) {
			throw new TypeError( '"this" is null or not defined' );
		}

		var O = Object( this );

		var len = O.length >>> 0;

		// 4. If len is 0, return -1.
		if ( len === 0 ) {
			return -1;
		}

		var n = +fromIndex || 0;

		if ( Math.abs(n) === Infinity ) {
			n = 0;
		}

		if ( n >= len ) {
			return -1;
		}

		k = Math.max( n >= 0 ? n : len - Math.abs(n), 0);

		while ( k < len ) {
			var kValue;

			if ( k in O && O[k] === searchElement ) {
				return k;
			}
			k++;
		}

		return -1;
	};
}

// From https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Object/keys
if ( !Object.keys ) {
	Object.keys = (function() {
		'use strict';
		var hasOwnProperty = Object.prototype.hasOwnProperty,
			hasDontEnumBug = !({ toString: null }).propertyIsEnumerable('toString'),
			dontEnums = [
				'toString',
				'toLocaleString',
				'valueOf',
				'hasOwnProperty',
				'isPrototypeOf',
				'propertyIsEnumerable',
				'constructor'
			],
			dontEnumsLength = dontEnums.length;

		return function(obj) {
			if (typeof obj !== 'object' && (typeof obj !== 'function' || obj === null)) {
				throw new TypeError('Object.keys called on non-object');
			}

			var result = [], prop, i;

			for (prop in obj) {
				if (hasOwnProperty.call(obj, prop)) {
					result.push(prop);
				}
			}

			if (hasDontEnumBug) {
				for (i = 0; i < dontEnumsLength; i++) {
					if (hasOwnProperty.call(obj, dontEnums[i])) {
						result.push(dontEnums[i]);
					}
				}
			}
			return result;
		};
	}());
}

/**
 * Test to see if an element supports
 * an attribute.
 */
function elementSupportsAttribute( element, attribute ) {
	var testElem = document.createElement( element );
	return ( attribute in testElem );
}

/**
 * Cross-browser event handling method
 * Used instead of jQuery's event handling
 */
function addEvent( element, event, handler ) {
  if( element.addEventListener ) {
    return element.addEventListener( event, handler, false );
  } else if( element.attachEvent ) {
    return element.attachEvent('on' + event, handler);
  } else {
    return element['on' + event] = handler;
  }
}


/**
 * based on https://github.com/inuyaksa/jquery.nicescroll/blob/master/jquery.nicescroll.js
 * Just a check to see if an element has a parent up to a certain element.
 */
function hasParent( e, p ) {
	if (!e) return false;
	var el = e.target || e.srcElement || e || false;
	while ( el && el != p ) {
		el = el.parentNode || false;
	}
	return (el !== false);
};

 /**
  * Extend object function
  */
function extend( a, b ) {
	// Loop through all the keys in the second object
	for ( var key in b ) {
		// Check to make sure the property belongs to b
		if ( b.hasOwnProperty( key ) ) {
			a[key] = b[key];
		}
	}
	return a;
};

/**
 * Function to handle converting a node List to an array
 * Used because IE < 9 doesn't support Array.prototype.slice.call(elements)
 */
function nodeListToArray( nodes ) {
	var arr = [];
	for ( var i = 0; i < nodes.length; (i+=1) ) {
		arr.push( nodes[i] );
	}
	return arr;
}

/**
 * Function to create cookies.
 */
function createCookie(name, value, days) {
    if (days) {
        var date = new Date();
        date.setTime(date.getTime() + (days*24*60*60*1000));
        var expires = "; expires=" + date.toGMTString();
    }
    else var expires = "";
    document.cookie = name + "=" + value + expires + "; path=/";
}

/**
 * Function to read cookies.
 */
function readCookie(name) {
    var nameEQ = name + "=";
    var ca = document.cookie.split(';');
    for(var i=0;i < ca.length;i++) {
        var c = ca[i];
        while (c.charAt(0)==' ') c = c.substring(1,c.length);
        if (c.indexOf(nameEQ) == 0) return c.substring(nameEQ.length,c.length);
    }
    return null;
}

/**
 * Function to delete cookies.
 */
function deleteCookie(name) {
    createCookie(name,"",-1);
}

/**
 * Function to check HTML5 Storage existence.
 */
function supportsHTML5Storage() {
	try {
		return 'localStorage' in window && window['localStorage'] !== null;
	} catch(e) {
		return false;
	}
}

/**
 * Function to convert a URL to an array of parameters.
 */
function URLToArray( url ) {
	var request = {};
	var pairs = url.substring(url.indexOf('?') + 1).split('&');
	for (var i = 0; i < pairs.length; i++) {
		var pair = pairs[i].split('=');
		request[decodeURIComponent(pair[0])] = decodeURIComponent(pair[1]);
	}
	return request;
}

/**
 * Function to get the protocol and hostname.
 */
function getProtocolandHost() {
	var retString = "";
	if ( window.location.port )
		retString = window.location.protocol + "//" + window.location.hostname + ":" + window.location.port + "/";
	else
		retString = window.location.protocol + "//" + window.location.hostname + "/";

	return retString;
};

/**
 * Function to grab the user's timezone from the browser.
 */
function getTimeZoneAbbreviation() {
	var curDate = new Date(),
		splitDate = curDate.toString().split(/\s+/),
		timeZoneAbbrev = splitDate[splitDate.length - 1].replace(/[\(\)]/g, '');

	return timeZoneAbbrev;
}

/**
* Function to detect mobile browsers
*/
function detect_mobile_browser() {
   if( navigator.userAgent.match(/Android/i)
	   || navigator.userAgent.match(/webOS/i)
	   || navigator.userAgent.match(/iPhone/i)
	   || navigator.userAgent.match(/iPad/i)
	   || navigator.userAgent.match(/iPod/i)
	   || navigator.userAgent.match(/BlackBerry/i)
	   || navigator.userAgent.match(/Windows Phone/i)
	){
	   return true;
	}
	else {
	   return false;
	}
}

/**
 * Function to get all the query parameters.
 */
function getQueryParameters() {
	var queryString = [],
		queryParams = window.location.search.split('?');

	// We have query parameters in the URL so perform the split and storage
	if ( queryParams.length > 1 ) {
		var params = window.location.search.split('?')[1].split('&');
		for( var i = 0; i < params.length; i++ ) {
			var split_params = params[i].split('=');
			var key = split_params[0];
			var value = decodeURIComponent(split_params[1]);
			queryString[key] = value;
		}
	}

	return queryString;
}
